<template>
  <div style="
          position: absolute; 
          top: 110%; 
          left: -0%;
      ">
    <Button @click="copyToClipBoard(index1)">
      Copy Table
    </Button>
    <div ref="exportContent">
      <table 
        style="
          visibility: hidden;
        " 
        class="CopyTable-exportTable"
        :id="index1 + 'table'"
      >
        <!-- TableTitle -->
        <tr>
          <th 
            v-for="(header, index ) in this.headerTable[0]" 
            :key="index" 
          >
            <div v-if="index === 0">
            </div>
            <div v-html="header"></div>
          </th>
        </tr>
        <!-- Data -->
        <tr v-for="(row, rowIndex) in this.segmentationItemValues[index1]" :key="rowIndex">
          <td v-for="(cell, cellIndex) in row" :key="cellIndex">
            <div v-if="cellIndex === 0" class="CopyTable-exportTable-rowDesc">
              {{ formatNumber(cell) }}
            </div>
            <div v-else>
              {{ formatNumber(cell) }}
            </div>
          </td>
        </tr>
        <!-- Total -->
        <tr v-for="(total, totalIndex) in this.totals[index1]" :key="totalIndex">
          <td v-for="(cell, cellIndex) in total" :key="cellIndex">
            <div class="CopyTable-exportTable-total">
              {{ formatNumber(cell) }}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  name: 'CopyTableSingleSegmentation',
  emits: ['addTablesForExportWord'],
  props: [
    'index1', 
    'segmentationTitles', 
    'headerTable', 
    'segmentationItemValues', 
    'totals', 
    'valueDisplayOption',
    'segmentationTitle'
  ],

  components: {
    Button
  },

  data() {
    return {
      alwaysFalse: true,
    };
  },

  mounted() {
    this.addTablesForWord();
  },

  methods: {
    copyToClipBoard(index1) {
      // ON CopyTableCountryData.vue WE IMPROVE THE CHART TO HIDE OFF THE SCREEN IF NEED CHANGE THIS LOGIC

      var range = document.createRange();
      let containerNode = document.getElementById(index1 + 'table'); //// this part
      // console.log(containerNode);
      containerNode.style.visibility = 'visible';

      range.selectNode(containerNode); //// this part
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      containerNode.style.visibility = 'hidden';
      alert("data copied");

    },

    formatNumber(num) {
      if(num){
        // split int and decimals of number
        let [int, decimal] = num.toString().split('.');
  
        //format int with , 
        int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
        return decimal ? `${int}.${decimal}` : int
      }
      else{
        return num 
      }
    },

    addTablesForWord(){
      const exportContent = {
        content: this.$refs.exportContent.innerHTML,
        name: 'singleSegmentation'+' table2.1',
        segmentation: this.segmentationTitle,
        order: this.index1
      } ;
      // console.log('2');
      this.$emit("addTablesForExportWord",  {exportContent: exportContent, order:exportContent.order})
    },
  }
};
</script>

<style scoped>
/* styles class are on CopyTableSingleSegmentationSimplified */
</style>