<template>
    <div class="container-datas" style="font-size: 1rem; margin-top: 2rem;">
        <!-- NOT USE -->
        <!-- <ExportToWordDoc
            :loadingFinish="loadingFinish"
            :singleTableToWord="singleTableToWord"
            :singleChartToWord="singleChartToWord"
        /> -->
        <!-- EndRegion ExportToDoc -->
        <div v-if="toChartSegmItems.length > 0">
            <div v-for="(count, index1) in toChartSegmItems " :key="index1" style="
                    display:flex;
                    flex-direction: column;
                    gap: 2rem;
                    width: 100%;
                ">
                <h4>
                    <strong>{{ topicName }}</strong>  Market Summary by {{ this.segmentationTitles[index1][0][0] }} US$ {{this.valueDisplayOption}}  
                </h4>
                <!-- 1st group of graphics -->
                <div style="
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr 1fr ;
                        gap: 8rem;
                        margin-bottom: 5rem;
                        padding: 0rem 2.5rem 0rem 2.5rem;
                    ">
                    <div style="
                            width: 100%; 
                        ">
                        <div style=" position: relative;">
                            <!-- Simplified Table  -->
                            <table style="width: 100%; height: 100%;">
                                <!-- headerTable -->
                                <tr class="topicOutputTrTextAlign">
                                    <th v-for="(header, index) in this.simplifiedHeaderTable[0]" :key="index"
                                        class="topicOutputTh">
                                        <div v-if="index === 0">
                                            {{ this.segmentationTitles[index1][0][0] }} US$ {{this.valueDisplayOption}}
                                        </div>
                                        <div v-html="header"  style="display: flex; justify-content: flex-end; align-items: center;"></div>
                                    </th>
                                </tr>
                                <!-- Data -->
                                <tr v-for="(row, rowIndex) in this.simplifiedSegmentationItemValues[index1]" :key="rowIndex"
                                    class="topicOutputTrTextAlign">
                                    <td v-for="(cell, cellIndex) in row" :key="cellIndex" class="topicOutputTh">
                                        {{ formatNumber(cell) }}
                                    </td>
                                </tr>
                                <!-- Total -->
                                <tr v-for="(total, totalIndex) in this.simplifiedTotals[index1]" :key="totalIndex"
                                    class="topicOutputTrTextAlign">
                                    <td v-for="(cell, cellIndex) in total" :key="cellIndex" class="topicOutputTh">
                                        <div v-if="cellIndex === 0" style=" text-align: right; font-weight: bold; ">
                                            {{ formatNumber(cell) }}
                                        </div>
                                        <div v-else style="font-weight: bold; ">
                                            {{ formatNumber(cell) }}
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <div v-if="valueDisplayOption">
                                <CopyTableSingleSegmentationSimplified
                                    :index1="index1"
                                    :segmentationTitles="segmentationTitles" 
                                    :simplifiedHeaderTable="simplifiedHeaderTable"
                                    :simplifiedSegmentationItemValues="simplifiedSegmentationItemValues"
                                    :simplifiedTotals="simplifiedTotals"
                                    :valueDisplayOption="valueDisplayOption"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- Chart figure1 -->
                    <div style="width: 100%; height: 500px;" v-if=" loadingStep >= 1 && valueDisplayOption">
                        <!-- {{toFigure1ChartYears}} -->
                        <!-- {{toFigure1SegmItems[index1]}} -->
                        <!-- {{ valueDisplayOption  }} -->
                        <!-- {{ this.convertValueDisplayOption(maxValue[index1]) }} -->
                        <!-- {{ this.convertValueDisplayOption(maxValueFigure1[index1]) }} -->
                        <!-- {{ topicName }} -->
                        <!-- {{ this.segmentationTitles[index1][0][0] }}  -->
                        <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 1rem;">
                            <span style="font-size: 16px;font-weight:bold;">
                                {{topicName}} Market Summary by {{segmentationTitles[index1][0][0]}}
                            </span>
                            <span  style="color:#929292; font-size: 12px;">
                                Market Size {{toFigure1ChartYears[0]}}, Market Size {{toFigure1ChartYears[1]}}, CAGR {{toFigure1ChartYears[0]}}-{{toFigure1ChartYears[1]}}
                            </span>
                        </div>
                        <VueAnychartFigure1 
                            :years="toFigure1ChartYears" 
                            :data="toFigure1SegmItems[index1]" 
                            :maxValue="this.convertValueDisplayOption(maxValueFigure1[index1])"
                            :valueDisplayOption="valueDisplayOption"
                            @next-step="nextStep"
                            :topicName="topicName"
                            :segmentationName="segmentationTitles[index1][0][0]"
                        />
                        <!-- Taken By all Datas -->
                        <!-- :maxValue="this.convertValueDisplayOption(maxValue[index1])" -->
                    </div>
                    <div v-else style="display: flex; justify-content: center; align-items: center;">
                        <ProgressSpinner/>
                    </div>

                    <!-- Chart figure2 -->
                    <div style="width: 100%; height: 350px;" v-if="loadingStep >= 2 && valueDisplayOption">
                        <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 1rem;">
                            <span style="font-size: 16px;font-weight:bold;">{{topicName}} Market Size by {{segmentationTitles[index1][0][0]}}</span>
                            <span  style="color:#929292; font-size: 12px;">Market Size {{toFigure1ChartYears[0]}}-{{toFigure1ChartYears[1]}}</span>
                        </div>
                        <VueAnychartFigure2 
                            :years="toFigure1ChartYears"
                            :data="toFigure2ChartData[index1]" 
                            :valueDisplayOption="valueDisplayOption"
                            @next-step="nextStep"
                            :topicName="topicName"
                            :segmentationName="segmentationTitles[index1][0][0]"
                        />
                    </div>
                    <div v-else style="display: flex; justify-content: center; align-items: center;">
                        <ProgressSpinner />
                    </div>

                    <!-- Chart figure16.1 figure16.2 -->

                    <div style="width: 100%; height: 350px;" v-if="loadingStep >= 3">
                        <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 1rem;">
                            <span style="font-size: 16px;font-weight:bold;">{{topicName}} Market CAGR by {{segmentationTitles[index1][0][0]}}</span>
                            <span  style="color:#929292; font-size: 12px;">
                                CAGR {{toFigure1ChartYears[0]}}-{{toFigure1ChartYears[1]}}
                            </span>
                        </div>
                        
                        <VueAnychartFigureRadar
                            :years="toFigure1ChartYears"
                            :data="toFigureSpiderData[index1]"
                            @next-step="nextStep"
                            :topicName="topicName"
                            :segmentationName="segmentationTitles[index1][0][0]"
                        />
                    </div>
                    <div v-else style="display: flex; justify-content: center; align-items: center;">
                        <ProgressSpinner 
                        />
                    </div>

                </div>
                <!-- 2nd group of graphics -->
                <h4><strong>{{ topicName  }}</strong>  Market Forecast to  {{ toChartYears[0][toChartYears[0].length -1] }} by {{ this.segmentationTitles[index1][0][0] }} US$ {{this.valueDisplayOption}} </h4>
                <div style="
                        display: grid;
                        grid-template-columns: 1fr;
                        /* grid-template-rows: 1fr; */
                        position: relative;
                        gap: 8rem;
                        margin-bottom: 8rem;
                        padding: 0rem 2.5rem 0rem 2.5rem;
                    ">
                    <!-- TABLE 2.1 -->
                    <div style="
                            width: 100%;
                            position: relative;
                        "
                    >
                        <table style="width: 100%;">
                            <!-- headerTable -->
                            <tr class="topicOutputTrTextAlign">
                                <th v-for="(header, index) in this.headerTable[0]" :key="index" class="topicOutputTh">

                                    <div v-if="index === 0">
                                            {{ this.segmentationTitles[index1][0][0] }} US$ {{this.valueDisplayOption}}
                                    </div>
                                    <div v-html="header" style="display: flex; justify-content: flex-end; align-items: center;"></div>
                                </th>
                            </tr>
                            <!-- Data -->
                            <tr v-for="(row, rowIndex) in this.segmentationItemValues[index1]" :key="rowIndex"
                                class="topicOutputTrTextAlign">
                                <td v-for="(cell, cellIndex) in row" :key="cellIndex" class="topicOutputTh">
                                    {{ formatNumber(cell) }}
                                </td>
                            </tr>
                            <!-- Total -->
                            <tr v-for="(total, totalIndex) in this.totals[index1]" :key="totalIndex"
                                class="topicOutputTrTextAlign">
                                <td v-for="(cell, cellIndex) in total" :key="cellIndex" class="topicOutputTh">
                                    <div v-if="cellIndex === 0" style=" text-align: right; font-weight: bold; ">
                                        {{ formatNumber(cell) }}
                                    </div>
                                    <div v-else style="font-weight: bold; ">
                                        {{ formatNumber(cell) }}
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div v-if="valueDisplayOption">
                            <CopyTableSingleSegmentation 
                                :index1="index1" 
                                :segmentationTitles="this.segmentationTitles"
                                :headerTable="this.headerTable" 
                                :segmentationItemValues="this.segmentationItemValues"
                                :totals="this.totals"
                                :valueDisplayOption="this.valueDisplayOption" 
                                @addTablesForExportWord="addTablesForExportWord"
                                :segmentationTitle="this.segmentationTitles[index1][0][0]"
                            />
                        </div>
                        
                    </div>

                    <!-- CHART 2.1 -->
                    <div style="
                        display: grid;
                        grid-template-columns: 100%;
                    ">
                        <div style="
                                width: 100%; height: 500px;
                            "
                            v-if="loadingStep >= 4 && valueDisplayOption"
                        >
                            <div style="
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    margin-bottom: 1rem;
                                "
                            >
                                    <span style="font-size: 16px;font-weight:bold;">{{topicName}} Market to {{ toChartYears[0][toChartYears[0].length-1]}} by {{segmentationTitles[index1][0][0]}}</span>
                                    <span style="color:#929292; font-size: 12px;">
                                        Market Forecast {{toChartYears[0][0]}} - {{toChartYears[0][toChartYears[0].length -1 ]}}
                                    </span>
                            </div>

                            <VueAnychart 
                                :years="toChartYears[0]" 
                                :data="toChartData[index1][0]"
                                :total="totals[index1][0]"
                                :segmentationItems="toChartSegmItems[index1]" 
                                :output="output"
                                :segmentationVersusTitles="'Single segmentation Title'" 
                                @next-step="nextStep"
                                :topicName="topicName"
                                :segmentationName="segmentationTitles[index1][0][0]"
                                :valueDisplayOption="valueDisplayOption"
                                @addChartsForExportWord="addChartsForExportWord"
                                :index1="index1"
                            />
                        </div>
                        <div v-else style="display: flex; justify-content: center; align-items: center;">
                            <ProgressSpinner/>
                        </div>
                    </div>
                    <!-- CHART 2.2 -->
                    <div style="
                                display: grid;
                                grid-template-columns: 100%;
                            "
                        >
                            <div 
                                style="width: 100%; height: 500px;"
                                v-if="loadingStep >= 5"
                            >
                                <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 1rem;">
                                    <span style="font-size: 16px; font-weight: bold;">{{topicName}} Market Forecast to {{this.toFigure3ChartLastYear}} by {{segmentationTitles[index1][0][0]}}</span>
                                </div>
                                <VueAnychartFigure3 
                                    :data="this.toFigure3ChartDataYear[index1]"
                                    :year="this.toFigure3ChartFirstYear" 
                                    :firstYear="this.toFigure3ChartFirstYear"
                                    :lastYear="this.toFigure3ChartLastYear"
                                    :topicName="topicName"
                                    :segmentationName="segmentationTitles[index1][0][0]"
                                    @next-step="nextStep"
                                    :valueDisplayOption="valueDisplayOption"
                                    :FYValues="toChartData[index1][0][0]"
                                    :LYValues="toChartData[index1][0][toChartData[index1][0].length -1]"
                                    @addChartsForExportWord="addChartsForExportWord"
                                    :index1="index1"
                                />
                            </div>
                            <div v-else style="display: flex; justify-content: center; align-items: center;">
                                <ProgressSpinner/>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
// Imports
import CopyTableSingleSegmentation from './CopyTableSingleSegmentation.vue';
import CopyTableSingleSegmentationSimplified from './CopyTableSingleSegmentationSimplified.vue';
import VueAnychart from './VueAnychart.vue';
import VueAnychartFigure1 from './VueAnychartFigure1.vue';
import VueAnychartFigure2 from './VueAnychartFigure2.vue';
import VueAnychartFigure3 from './VueAnychartFigure3.vue';
import VueAnychartFigureRadar from './VueAnychartFigureRadar.vue';
import ProgressSpinner from 'primevue/progressspinner';
// import ExportToWordDoc from './ExportToWordDoc.vue';


export default {
    name: 'programSingleOutputPreview',
    components: {
        CopyTableSingleSegmentation,
        CopyTableSingleSegmentationSimplified,
        VueAnychart,
        VueAnychartFigure1,
        VueAnychartFigure2,
        VueAnychartFigure3,
        VueAnychartFigureRadar,
        // ExportToWordDoc,
        ProgressSpinner,
    },
    emits: [],
    props: ['output','topicName','valueDisplayOption','decimalsOption'],
    data() {
        return {
            // outputViews for tables
            segmentationTitles: [],
            headerTable: [],
            segmentationItemValues: [],

            // output for Charts
            toChartYears: [],
            toChartData: [],
            toChartSegmItems: [],

            // totals
            totals: [],
            simplifiedTotals: [],

            // Simplified Table
            simplifiedSegmentationTitles: [],
            simplifiedHeaderTable: [],
            simplifiedSegmentationItemValues: [],

            //output For Figure1 Charts
            toFigure1ChartYears: [],
            toFigure1ChartData: [],
            toFigure1SegmItems: [],

            //output For Figure2 Charts
            toFigure2ChartData: [],

            // output for FigureSpider Chart
                toFigureSpiderData: [],

            // output For Figure3 Charts
            toFigure3ChartDataYear:[],
            toFigure3ChartDataFirstYear: [], //remove
            toFigure3ChartDataLastYear: [], //remove

            toFigure3ChartFirstYear: null,
            toFigure3ChartLastYear: null,

            toFigure3TotalFirstYear: null,
            toFigure3TotalLastYear: null,


            // Max Value
            maxValue: [], //for figure 1 taken for all datas 
            maxValueFigure1: [], // for figure taken by datas from figure 1
            // no need for figure 2 cause we get MaxValue with the data we send 

            // sync loading
            loadingStep: 0,  //for charts
            loadingSteps: 6, //for charts
            loadingFinish: false, // LOADING FINISH IS === TRUE WHEN ALL CHARTS ARE COMPLETED RENDERED

            loadingStepTables: 0,
            loadingStepsTables:3,

            // export To Word
            singleTableToWord: [],
            singleChartToWord: [],
        }
    },

    mounted() {
        // output
        this.assignOutput();
        this.assignOutputToChart() //have the important
        this.getTotalRow();

        
        this.assignOutputSimplified();
        this.assignOutputToFigure1Chart();
        this.getTotalRowSimplified();

        
        this.assignOutputToFigure2Chart();
        this.assignOutputToFigureSpiderChart();
        this.assignOutputToFigure3Chart();

        this.nextStep();
    },

    methods: {
        nextStep(){
            if(this.loadingStep < this.loadingSteps){
                setTimeout(() => {
                    this.loadingStep++ 
                // }, 3000);
                }, 1500);
                if(this.loadingStep + 1 === this.loadingSteps){
                    this.loadingFinish = true
                }
            }
        },

        assignOutput() {
            //  SHOW ARRAY ['US$M',2022,2023,2024,2025,2026,2027,2028]
            let years = ['']
            this.output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues.map(item => years.push(item.year))
            // ADD TO years [SUM , CAGR] TO SHOW
            //  SHOW ARRAY ['US$M',2022,2023,2024,2025,2026,2027,2028,SUM, CAGR]

            const fyString = years[1].toString()
            const lyString = years[years.length - 1].toString()
            let sumHeader = '∑'+fyString.slice(-2) +'-'+ lyString.slice(-2);
            years.push(sumHeader);


            let cagr = 'CAGR <br />'+fyString.slice(-2) +'-'+ lyString.slice(-2); 
            years.push(cagr);

            this.headerTable.push(years);

            for (let index = 0; index < this.output.length; index++) {
                let titles = [];
                // this.segmentationTitles SHOW MAIN TITLE 
                const segmentationName = this.output[index].segmentations[0].name;
                titles.push([segmentationName]);
                this.segmentationTitles.push(titles)


                const versusValueFinal = []
                this.output[index].segmentations[0].segmentationItems.map(item => {
                    // versusValueFinal IS THE ARRAY THAT SHOW SEGMVERSUSTITLE AND THE MARKETDATA
                    // ['segmentationItem', 1,2,3,4,5,6,7]

                    let segmItemName
                    const versusValue = []
                    if (item.regionId != null) {
                        segmItemName = item.region.name
                    }
                    else {
                        segmItemName = item.name
                    }
                    versusValue.push(segmItemName)

                    item.documentProgramTopic.documentProgramValues.map(
                        item => {
                            // set MaxValue
                            if(!this.maxValue[index]){
                                this.maxValue[index] = item.value
                            } 
                            else{
                                if(item.value > this.maxValue[index]){
                                    this.maxValue[index] = item.value
                                }
                            }
                            // push datavalue to DataArray
                            versusValue.push(this.tofixValueOptionDecimate(item.value))
                        }
                    )
                    // SUM
                    // sum from frontend
                    let sumArray = [...versusValue]
                    sumArray.shift()
                    let sum = this.toFixDecimate3(sumArray.reduce((acc, val) => acc +parseFloat(val),0)); 
                    //Sum from backend
                    // let sum1 = this.tofixValueOptionDecimate(item.documentProgramTopic.sumAll);
                    versusValue.push(sum)

                    // CAGR
                    let cagr 
                    if(item.documentProgramTopic.cagr !== null){  // NULL VALIDATION TODO: ASK 
                        cagr = item.documentProgramTopic.cagr.toFixed(1) + '%';
                    }
                    else{
                        cagr = 0 + '%'
                    }
                    versusValue.push(cagr); // CAGR

                    versusValueFinal.push(versusValue)
                })
                this.segmentationItemValues.push(versusValueFinal)
            }
        },

        assignOutputToChart() {
            this.toChartData = [];
            this.toChartYears = [];

            // outputYears SHOW ARRAY OF YEARS ['2024','2025','2026']
            const outputViews = []
            let years = []
            this.output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues.map(item => years.push(item.year))
            outputViews.push(years)
            this.toChartYears = outputViews;

            // outputData SHOW ARRAY OF ARRAY FOR SHOWING TO CHART
            // 
            // [
            //     [[1,2,3],[4,5,6]],
            //     [[7,8,9],[10,11,12]],
            // ]
            // EXPLAIN
            // [
            //     segment1
            //     [
            //         north America[0,0,0],  europe [0,0,0]
            //     ] , 
            //     segment2
            //     [
            //         north America[0,0,0], europe[0,0,0]
            //     ] , 
            //     segment3
            //     [
            //         north America[0,0,0], europe[0,0,0]
            //     ]
            // ]

            let outputData = []
            for (let index = 0; index < this.output.length; index++) {
                const versusValueFinal = []
                this.output[index].segmentations[0].segmentationItems.map(item => {
                    const versusValue = []

                    item.documentProgramTopic.documentProgramValues.map(
                        item2 => {
                            versusValue.push(this.tofixValueOptionDecimate(item2.value))
                        }
                    )
                    versusValueFinal.push(versusValue)
                })
                outputData.push([versusValueFinal])
            }

            // outputDataFinalle SHOW ARRAY BY YEARS COUNT SHOW VALUE BY COLUMN 
            // [
            //     [
            //         [1,2],  YEAR 2024
            //         [3,4],  YEAR 2025
            //         [segmentationItem1, segmentationItem2]
            //     ],
            //     [
            //         [1,2],  YEAR 2024
            //         [3,4],  YEAR 2025
            //         [segmentationItem1, segmentationItem2]
            //     ],
            //     [
            //         [1,2],  YEAR 2024
            //         [3,4],  YEAR 2025
            //         [segmentationItem1, segmentationItem2]
            //     ]
            // ]


            let outputDataFinalle = []

            outputData.map((output) => {
                let bData = []
                output.map((item) => {
                    let outputArray = []
                    outputViews[0].map((year, index) => {
                        let aData = []
                        item.map(data => {
                            aData.push(data[index])
                        })
                        outputArray.push(aData)
                    })
                    bData.push(outputArray);
                })
                outputDataFinalle.push(bData);
            })

            this.toChartData = outputDataFinalle

            //SegmItemsRival
            // SHOW THE SEGMENTATIONITEMS FOR LEGACY ['SEGMENTATION1','SEGMENTATION2','SEGMENTATION3']

            let Array = [];
            for (let index = 0; index < this.output.length; index++) {
                let SegmItemsRivalNameArray0 = [];
                this.output[index].segmentations[0].segmentationItems.map(item => {
                    if (item.regionId != null) { //for !Region
                        SegmItemsRivalNameArray0.push(item.region.name)
                    }
                    else {
                        SegmItemsRivalNameArray0.push(item.name)
                    }
                })
                Array.push(SegmItemsRivalNameArray0);
            }
            this.toChartSegmItems = Array

        },

        assignOutputSimplified() {
            // SHOW ARRAY ['US$M', FIRSTYEAR, LASTYEAR]
            let years = ['']
            let newYears = []
            this.output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues.map(item => newYears.push(item.year))

            const firstElement = newYears[0]
            const lastElement = newYears[newYears.length - 1]

            years.push(firstElement);
            years.push(lastElement);

            // ADD TO YEARS [SUM, CARG] TO SHOW
            // SHOW ARRAY ['US$M', FIRSTYEAR, LASTYEAR, SUM, GAGR]

            const fyString = firstElement.toString();
            const lyString = lastElement.toString();

            let sumHeader = '∑'+fyString.slice(-2) +'-'+ lyString.slice(-2);
            years.push(sumHeader);

            // years.push('CAGR');
            let cagr = 'CAGR <br />'+fyString.slice(-2) +'-'+ lyString.slice(-2); 
            years.push(cagr);

            this.simplifiedHeaderTable.push(years);
            

            for (let index = 0; index < this.output.length; index++) {
                let titles = [];
                // this.simplifiedSegmentationTitles SHOW MAIN TITLE 
                const segmentationName = this.output[index].segmentations[0].name;
                titles.push([segmentationName]);
                this.simplifiedSegmentationTitles.push(titles)


                const versusValueFinal = []
                this.output[index].segmentations[0].segmentationItems.forEach(item => {
                    // versusValueFinal IS THE ARRAY THAT SHOW SEGMVERSUSTITLE AND THE MARKETDATA
                    // ['segmentationItem', 1,2,3,4,5,6,7]

                    let segmItemName =item.regionId !=null ?item.region.name  : item.name;
                    const versusValue = [segmItemName]

                    // item.documentProgramTopic.documentProgramValues
                    const dataValues = item.documentProgramTopic.documentProgramValues;

                    // FistYearData
                    const firstYearData = dataValues[0]
                    versusValue.push(this.tofixValueOptionDecimate(firstYearData.value))

                    const lastYearData = dataValues[dataValues.length - 1]
                    versusValue.push(this.tofixValueOptionDecimate(lastYearData.value))
                    
                    // set MaxValue
                    if(!this.maxValueFigure1[index] || firstYearData.value > this.maxValueFigure1[index]){
                        this.maxValueFigure1[index] = firstYearData.value
                    }
                    if (!this.maxValueFigure1[index] || lastYearData.value > this.maxValueFigure1[index]) {
                        this.maxValueFigure1[index] = lastYearData.value;
                    }
                    
                    // SUM
                    let sum = 0;
                    item.documentProgramTopic.documentProgramValues.forEach(item => {
                        let converted = this.tofixValueOptionDecimate(item.value) 
                        sum += parseFloat(converted)
                        // sum += this.tofixValueOptionDecimate(item.value)
                    })

                    versusValue.push(this.toFixDecimate3(sum));

                    // CAGR
                    let cagr
                    if(item.documentProgramTopic.cagr !== null){ // NULL VALIDATION TODO: ASK 
                        cagr = item.documentProgramTopic.cagr.toFixed(1) + '%'; 
                    } 
                    else{
                        cagr = 0 + '%';
                    }
                     
                    versusValue.push(cagr);
                    versusValueFinal.push(versusValue)
                })

                this.simplifiedSegmentationItemValues.push(versusValueFinal)
            }
        },

        assignOutputToFigure1Chart() {
            // to Show for Table Chart
            // ['segm1',0,1]
            // ['segm1',0,1]
            // ['segm1',0,1]

            for (let index = 0; index < this.output.length; index++) {
                const versusValueFinal = []
                this.output[index].segmentations[0].segmentationItems.map((item) => {
                    // versusValueFinal IS THE ARRAY THAT SHOW SEGMVERSUSTITLE AND THE MARKETDATA
                    // ['segmentationItem']
                    let segmItemName
                    const versusValue = []
                    if (item.regionId != null) {
                        segmItemName = item.region.name
                    }
                    else {
                        segmItemName = item.name
                    }
                    versusValue.push(segmItemName)

                    // ['segmentationItem',1,2]
                    // item.documentProgramTopic.documentProgramValues
                    const dataValues = item.documentProgramTopic.documentProgramValues
                    // Get Max Value 

                    const firstYearData = dataValues[0]
                    versusValue.push( this.tofixValueOptionDecimate(firstYearData.value))

                    const lastYearData = dataValues[dataValues.length - 1]
                    versusValue.push( this.tofixValueOptionDecimate(lastYearData.value))


                    // add Cagar Formula['segmentationItem',1,2, CAGR Formula]
                    // Todo Add Backend Integration;
                    // 
                    let cagrFormula
                    if(item.documentProgramTopic.cagr !== null){
                        cagrFormula = item.documentProgramTopic.cagr.toFixed(1) + '%';
                    } 
                    else{
                        cagrFormula = 0 + '%';
                    }


                    versusValue.push(cagrFormula);
                    versusValueFinal.push(versusValue)
                })
                this.toFigure1SegmItems.push(versusValueFinal)
            }

            // to show [2024,2025]

            let newYears = []
            this.output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues.map(item => newYears.push(item.year))

            const firstElement = newYears[0]
            const lastElement = newYears[newYears.length - 1]

            this.toFigure1ChartYears.push(firstElement);
            this.toFigure1ChartYears.push(lastElement);
        },

        assignOutputToFigure2Chart() {
            // to Show For Table Chart
            // ['segm1',10]
            // ['segm2',10]
            // ['segm3',10]

            for (let index = 0; index < this.output.length; index++) {
                const versusValueFinal = [];

                this.output[index].segmentations[0].segmentationItems.map(item => {
                    // versus ValueFinal Is the array that show title, and Sum 
                    // [segmItem , 19]
                    let segmItemName;

                    const versusValue = []
                    if (item.regionId != null) {
                        segmItemName = item.region.name
                    }
                    else {
                        segmItemName = item.name
                    }
                    versusValue.push(segmItemName)

                    let sum = 0;
                    item.documentProgramTopic.documentProgramValues.map(item => {
                        let converted = this.tofixValueOptionDecimate(item.value) 
                        sum += parseFloat(converted)
                    })

                    versusValue.push(this.toFixDecimate3(sum));

                    // versusValue.push( this.tofixValueOptionDecimate(sum))
                    versusValueFinal.push(versusValue)
                })
                this.toFigure2ChartData.push(versusValueFinal)
            }
        },

        assignOutputToFigureSpiderChart(){
            for(let index = 0; index < this.output.length; index++){
                const versusValueFinal = []
                this.output[index].segmentations[0].segmentationItems.map(
                    item => {
                        let segmItemName
                        const versusValue = []
                        if(item.regionId != null){
                            segmItemName = item.region.name;
                        }
                        else{
                            segmItemName = item.name;
                        }

                        versusValue.push(segmItemName)

                        let cagrFormula 
                        if(item.documentProgramTopic.cagr !== null){// NULL VALIDATION TODO: ASK 
                            cagrFormula = item.documentProgramTopic.cagr.toFixed(1); 
                        }
                        else{
                            cagrFormula = 0; 
                        }

                        versusValue.push(cagrFormula);
                        versusValueFinal.push(versusValue)
                })
                this.toFigureSpiderData.push(versusValueFinal)
            }
        },

        assignOutputToFigure3Chart() {
            // To Show PieChart
            // [
            //     {x: "segm1", value:10, value:11},
            //     {x: "segm2", value:12, value:13},
            //     {x: "segm3", value:18, value:19},
            //     {x: "segm4", value:11, value:12},
            //     {x: "segm5", value:9,  value:10},
            // ]

            // To Show PieChart
            // [
            //     ['segm1',FY1,LY2,sum],
            //     ['segm2',FY1,LY2,sum],
            //     ['segm3',FY1,LY2,sum],
            //     ['segm4',FY1,LY2,sum],
            // ]

            for(let index = 0; index < this.output.length; index++){
                const versusValueFinalYear = [];

                this.output[index].segmentations[0].segmentationItems.map(item=>{
                    let segmItemName; 

                    if(item.regionId != null){
                        segmItemName = item.region.name
                    }
                    else{
                        segmItemName = item.name
                    }

                    let valueFirstYear = [
                        segmItemName, //segmName
                        this.tofixValueOptionDecimate(
                            item
                            .documentProgramTopic
                            .documentProgramValues[0]
                            .value
                        ), // FirstYear
                        this.tofixValueOptionDecimate(
                            item
                            .documentProgramTopic
                            .documentProgramValues[item.documentProgramTopic.documentProgramValues.length - 1]
                            .value
                        )  // lastYear
                        //TODO SUM
                    ]
                    versusValueFinalYear.push(valueFirstYear)
                })

                this.toFigure3ChartDataYear.push(versusValueFinalYear)
            }

            this.toFigure3ChartFirstYear = this.output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues[0].year;
            this.toFigure3ChartLastYear = this.output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues[this.output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues.length - 1].year;
        },

        getTotalRow() {
            // CLONE DEEP ARRAY 
            let segmentationItemValuesClone = JSON.parse(JSON.stringify(this.segmentationItemValues));
            //GET TOTAL
            segmentationItemValuesClone.map((segmItem) => {
                let b = []
                segmItem.map((si) => {
                    if (b.length === 0) {
                        let totArray = []
                        si.map((s, index) => {
                            if (index === 0) {
                                totArray.push("Total")
                            } else {
                                if(index !== si.length -1){
                                    if (typeof s !== "number") {
                                        let parseToNumber = Number(s);
                                        totArray.push(parseToNumber)
                                    }
                                    else {
                                        totArray.push(s)
                                    }
                                }
                            }
                        })
                        b.push(totArray)
                    }
                    else {
                        si.map((s, index2) => {
                            if (index2 !== 0 && index2 !== si.length -1) {
                                if (typeof s !== "number") {
                                    let parseToNumber = Number(s);
                                    b[0][index2] += parseToNumber;
                                }
                                else {
                                    b[0][index2] += s;
                                }
                            }
                        })
                    }
                })

                let c = [];
                const d = b[0].map((item, index) => {
                    if(index != 0){
                        return item = this.toFixDecimate3(item)
                    }
                    else{
                        return item
                    }
                })

                // add CAGR to total row
                const cagrValue = this.calculateCAGR(d).toFixed(1) + '%';

                d.push(cagrValue);

                // add d array to c  
                c.push(d);

                this.totals.push(c);
            })
        },

        getTotalRowSimplified() {
            // CLONE DEEP ARRAY
            let segmentationItemValuesClone = JSON.parse(JSON.stringify(this.simplifiedSegmentationItemValues));

            segmentationItemValuesClone.map((segmItem) => {
                let b = []
                segmItem.map((si) => {
                    if (b.length === 0) {
                        let totArray = []
                        si.map((s, index) => {
                            if (index === 0) {
                                totArray.push("Total")
                            }
                            else {
                                if(index !== si.length -1 ){
                                    if (typeof s !== "number") {
                                        let parseToNumber = Number(s);
                                        totArray.push(parseToNumber)
                                    }
                                    else {
                                        totArray.push(s)
                                    }
                                }
                            }
                        })
                        b.push(totArray)
                    }
                    else {
                        si.map((s, index2) => {
                            if (index2 !== 0 && index2 !== si.length -1) {
                                if (typeof s !== "number") {
                                    let parseToNumber = Number(s);
                                    b[0][index2] += parseToNumber;
                                }
                                else {
                                    b[0][index2] += s;
                                }
                            }
                        })
                    }
                })

                let c = [];
                const d = b[0].map((item, index) => {
                    if(index != 0){
                        return item = this.toFixDecimate3(item)
                    }
                    else{
                        return item
                    }
                })

                // add cagr to total row
                const cagrValue = this.calculateCAGRSimplified(d).toFixed(1) + '%';
                d.push(cagrValue);

                // add D Array to c
                c.push(d);

                this.simplifiedTotals.push(c);
            })

        },

        // Convert Million, Billion && decimates 
        convertValueDisplayOption(data){
            if(this.valueDisplayOption === 'billions'){
                return data / 1000;
            }
            else{
                return data
            }
        },

        toFixDecimate3(cell) {
            if(this.decimalsOption === '0.'){
                if(Number.isInteger(cell)){
                    return cell
                }
                else{
                    return Math.round(cell);
                }
            }
            if(this.decimalsOption === '.0'){
                return this.roundTo(cell, 1).toFixed(1) 
            }
            if(this.decimalsOption === '.00'){
                return this.roundTo(cell, 2).toFixed(2)     
            }
            if(this.decimalsOption === '.000'){
                return this.roundTo(cell, 3).toFixed(3)     
            }
        },

        tofixValueOptionDecimate(data){
            const valueDisplay = this.convertValueDisplayOption(data);
            return this.toFixDecimate3(valueDisplay);
        },

        roundTo(num, decimals) {
            let factor = Math.pow(10, decimals);
            return Math.round(num * factor)/factor;
        },

        calculateCAGR(values){
            let val = [...values]
            val.shift();
            val.pop();

            let initialValue = val[0];
            let finalValue = val[val.length -1];
            let n = val.length -1
            
            const cagr = Math.pow(finalValue / initialValue, 1/n) -1; 
            return (cagr) ? cagr * 100 : 0  
        },

        calculateCAGRSimplified(values){
            let val = [...values]
            val.shift();
            val.pop();

            let initialValue = val[0];
            let finalValue = val[val.length - 1];
            //  using toFigure1ChartYears for getting  years sub  
            let n =this.toFigure1ChartYears[1] - this.toFigure1ChartYears[0];

            const cagr = Math.pow(finalValue / initialValue , 1/n) -1; 
            return (cagr) ? cagr * 100 : 0
        },

        formatNumber(num){
            if(num){
                // split int and decimals of number
                let [int , decimal] = num.toString().split('.');

                //format int with , 
                int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return decimal ? `${int}.${decimal}` : int
            }
            else{
                return num
            }
        },

        cleanData(){
            // outputViews for tables
            this.segmentationTitles= [];
            this.headerTable= [];
            this.segmentationItemValues= [];

            // output for Charts
            this.toChartYears= [];
            this.toChartData= [];
            this.toChartSegmItems= [];

            // totals
            this.totals= [];
            this.simplifiedTotals= [];

            // Simplified Table
            this.simplifiedSegmentationTitles= [];
            this.simplifiedHeaderTable= [];
            this.simplifiedSegmentationItemValues= [];

            //output For Figure1 Charts
            this.toFigure1ChartYears=[];
            this.toFigure1ChartData= [];
            this.toFigure1SegmItems=[];

            //output For Figure2 Charts
            this.toFigure2ChartData= [];

            // output for FigureSpider Chart
            this.toFigureSpiderData= [];

            // output For Figure3 Charts
            this.toFigure3ChartDataFirstYear= [];
            this.toFigure3ChartDataLastYear= [];

            this.toFigure3ChartFirstYear= null;
            this.toFigure3ChartLastYear= null;

            // Max Value
            this.maxValue= [];//for figure 1 taken for all datas 
            this.maxValueFigure1= []; // for figure taken by datas from figure 1
            // no need for figure 2 cause we get MaxValue with the data we send 

            // sync loading
            this.loadingStep= 0;
        },

        // export To Word Functions 
        addTablesForExportWord(table){
            this.singleTableToWord[table.order] = [...(this.singleTableToWord[table.order] || []), table.exportContent];
        },
        addChartsForExportWord(chartOnPng){
            this.singleChartToWord[chartOnPng.order] = [...(this.singleChartToWord[chartOnPng.order] || []), chartOnPng.exportContent]
        }

    },
    watch: {
        'output': {
            async handler(newVal) {
                if (newVal) {
                    // console.log('new output');
                    this.cleanData(); 
                    this.assignOutput();
                    this.assignOutputToChart() //have the important
                    this.getTotalRow();

                    
                    this.assignOutputSimplified();
                    this.assignOutputToFigure1Chart();
                    this.getTotalRowSimplified();

                    this.assignOutputToFigure2Chart();
                    this.assignOutputToFigureSpiderChart();
                    this.assignOutputToFigure3Chart();

                    this.nextStep();
                }
            },
            deep: true,
        },
        'valueDisplayOption': {
            async handler(newVal) {
                if (newVal) {
                    this.cleanData(); 

                    this.assignOutput();
                    this.assignOutputToChart() //have the important
                    this.getTotalRow();

                    
                    this.assignOutputSimplified();
                    this.assignOutputToFigure1Chart();
                    this.getTotalRowSimplified();

                    
                    this.assignOutputToFigure2Chart();
                    this.assignOutputToFigureSpiderChart();
                    this.assignOutputToFigure3Chart();

                    this.nextStep();
                }
            },
            deep: true,
        },
        'decimalsOption': {
            async handler(newVal) {
                if (newVal) {
                    this.cleanData(); 

                    this.assignOutput();
                    this.assignOutputToChart() //have the important
                    this.getTotalRow();

                    
                    this.assignOutputSimplified();
                    this.assignOutputToFigure1Chart();
                    this.getTotalRowSimplified();

                    
                    this.assignOutputToFigure2Chart();
                    this.assignOutputToFigureSpiderChart();
                    this.assignOutputToFigure3Chart();

                    this.nextStep();
                }
            },
            deep: true,
        },
    },
}
</script>

<style scoped>
.topicOutputTrTextAlign>*:not(:first-child) {
    text-align: right;
}

.topicOutputTh {
    border: solid gray 1px;
    padding: 0 1.5% 0 1.5%;

}
</style>