<template>
  <div style="
        position: absolute; 
        top: 110%; 
        left: -0%;
    ">
    <Button @click="copyToClipBoard(index1, index)">
      Copy Table
    </Button>
    <table 
      style="
        visibility: hidden;
      "
      class="CopyTable-exportTable"
      :id="index1 + 'tableVersus' + index"
    >
      <!-- Segment SubTitle -->
      <!-- <tr>
        <td>
          <strong>{{ this.segmentationTitles[index][0] }}</strong>
        </td>
      </tr> -->
      <!-- TableTitle -->
      <tr>
        <th v-for="(header, index2 ) in this.headerTable[0]" :key="index2" >
          <div v-if="index2 === 0">
            <!-- {{ this.segmentationTitles[index1][index] }} US$ {{this.valueDisplayOption}} -->
          </div>
          <div v-html="header"></div>
        </th>
      </tr>
      <!-- Data -->
      <tr v-for="(row, rowIndex) in this.segmentationItemValues[index1][index]" :key="rowIndex">
        <td v-for="(cell, cellIndex) in row" :key="cellIndex">
          <div v-if="cellIndex === 0" class="CopyTable-exportTable-rowDesc">
            {{ formatNumber(cell) }}
          </div>
          <div v-else >
            {{ formatNumber(cell) }}
          </div>
        </td>
      </tr>
      <!-- Total -->
      <tr v-for="(total, totalIndex) in this.totals[index1][index]" :key="totalIndex">
        <td v-for="(cell, cellIndex) in total" :key="cellIndex">
          <div class="CopyTable-exportTable-total">
            {{ formatNumber(cell) }}
          </div>
        </td>
      </tr>

    </table>
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  name: 'CopyTable',
  emits: [],
  props: ['index', 'index1', 'SegmentationVersusTitles', 'segmentationTitles', 'headerTable', 'segmentationItemValues','totals','valueDisplayOption'],

  components: {
    Button
  },

  data() {
    return {
    };
  },
  methods: {
    copyToClipBoard(index1, index) {
      // ON CopyTableCountryData.vue WE IMPROVE THE CHART TO HIDE OFF THE SCREEN IF NEED CHANGE THIS LOGIC

      var range = document.createRange();
      let containerNode = document.getElementById(index1 + 'tableVersus' + index); //// this part
      // console.log(containerNode);
      containerNode.style.visibility = 'visible';

      range.selectNode(containerNode); //// this part
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      containerNode.style.visibility = 'hidden';
      alert("data copied");

    },

    formatNumber(num){
      if(num){
        // split int and decimals of number
        let [int , decimal] = num.toString().split('.');

        //format int with , 
        int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return decimal ? `${int}.${decimal}` : int
      }
      else{
        return num
      }
      
    }
  }
};
</script>

<style scoped>
/* styles class are on CopyTableSingleSegmentationSimplified */
</style>