<template>
    <div ref="chartContainer" class="chart" style="height: 100%;position: relative;">
    </div>

    <ExportSettingsDialog 
        :algo="'something3'"
        @exportToSvg="exportToSvgEmit"
    />

</template>

<script>
import Anychart from 'anychart';
import ExportSettingsDialog from './ExportSettingsDialog.vue'

import './AnychartThemes/market_forecast.js';

export default {
    props: ['years','data','topicName','segmentationName'],
    emits: ['next-step'],
    name:'VueAnychartFigureRadar',
    components:{
        ExportSettingsDialog
    },
    data(){
        return {
            chart: null, 
            radar: null,
        }
    },

    mounted(){
        this.init();
        this.$emit('next-step');
    },

    methods:{
        removeSeries() {
            if (this.chart.getSeriesCount()) {
                this.chart.removeSeriesAt(0);
            }
        },
        removeAllSeries() {
            if (this.chart.getSeriesCount()) {
                this.chart.removeAllSeries();
            }
        },
        addSeries(data) {
            this.delegateMethod('addSeries', data);
        },
        init(){
            let _Anychart = this.Anychart || Anychart;
            this.chart = _Anychart; 

            let dataSet = this.chart.data.set(this.data);

            // FY - firstYear
            // LY - lastYear
            // map data for the first series, take x from the zero column and value from the first column of data set
            let SeriesFYLY = dataSet.mapAs({x:0, value:1});

            // create radar chart
            this.radar = this.chart.radar();

            // LICENCE KEY
            this.chart.licenseKey('marketforecast.com-f857f242-9dccc95c'); 
            
            // Apply themes
            this.chart.theme('marketForecast');

            // Credits
            const currentYear = new Date().getFullYear();
            this.radar
                .credits()
                .enabled(true)
                .url(
                  'https://www.marketforecast.com'
                )
                .logoSrc('https://www.marketforecast.com/favicon.ico')
                .text(
                    `© Market Forecast ${currentYear}`
                ); 

            // set default series type
            this.radar.defaultSeriesType('area');

            // set chart data
            this.radar.data(SeriesFYLY);

            // set chart title
            // column title // hide column Title
            // this.radar
            //     .title()
            //     .enabled(true)
            //     .useHtml(true)
            //     .padding([0, 0, 10, 0])
            //     .text(
            //         `<span style="font-size: 16px;font-weight:bold;">${this.topicName} Market CAGR by ${this.segmentationName}</span>` +
            //         '<br/><span  style="color:#929292; font-size: 12px;">' +
            //         `CAGR ${this.years[0]}-${this.years[1]}</span>`
            //     );

            // set chart margin settings
            // this.radar.padding(20, 20, 10, 20);
            this.radar.padding(20, 5, 5, 5);

            // configure series
            let series = this.radar.area(SeriesFYLY);

            // series.tooltip().title().fontColor("#003A70"); // Set tooltip text color
            series.tooltip().title().fontColor("#FFFFFF"); // Set tooltip text color
            series.tooltip().title().fontSize(14); // Set tooltip font size
            // series.tooltip().fontColor("#003A70"); // Set tooltip text color
            series.tooltip().fontColor("#FFFFFF"); // Set tooltip text color
            series.tooltip().fontSize(12); // Set tooltip font size
            series.tooltip().padding(10); // Set tooltip padding

            // customize the tooltip for markerSeriesCAGR to include a percentage sign
            series.tooltip().format("CAGR: {%Value}{decimalsCount:1}%");

            // add labels to markerSeriesCAGR
            series.labels().enabled(true);
            series.labels().anchor('center-bottom');
            series.labels().format("{%Value}{decimalsCount:1}%"); // Customize the label format if needed
            series.labels().offsetX(15);
            series.labels().padding(5);
            series.labels().background().enabled(true).fill("#FFFFFF"); // Add background color

            // Hide Y-axis (radial axis) labels
            this.radar.yAxis().labels().enabled(false);

            // this.radar.container(this.$el).draw();
            this.radar.container(this.$refs.chartContainer).draw();
                
            this.radar.contextMenu().itemsFormatter(
                function(items){
                    // modify save as png
                    items['save-chart-as'].subMenu['save-chart-as-png'].action = function() {
                        this.radar.saveAsPng(
                            {
                                "width": 1000,
                                "height": 1000,
                                "quality": 0.3,
                                "filename":'SS03_'+this.topicName+'_Market_CAGR_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 
                    // modify save as jpg
                    items['save-chart-as'].subMenu['save-chart-as-jpg'].action = function() {
                        this.radar.saveAsJpg(
                            {
                                "width": 1000,
                                "height": 1000,
                                "quality": 0.3,
                                "forceTransparentWhite": false,
                                "filename":'SS03_'+this.topicName+'_Market_CAGR_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 

                    // modify save as pdf
                    items['save-chart-as'].subMenu['save-chart-as-svg'].action = function() {
                        this.radar.saveAsSvg(
                            // {
                            //     "paperSize": 'a4',
                            //     "landscape": true,
                            // }
                            {
                                "width": 1000,
                                "height": 1000,
                                "filename":'SS03_'+this.topicName+'_Market_CAGR_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 


                    // modify save as pdf
                    items['save-chart-as'].subMenu['save-chart-as-pdf'].action = function() {
                        this.radar.saveAsPdf(
                            {
                                "paperSize": 'a4',
                                "landscape": true,
                                "filename":'SS03_'+this.topicName+'_Market_CAGR_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 
                    return items;
                }.bind(this)
            )

            this.chart.exports.filename('SS03_'+this.topicName+'_Market_CAGR_by_'+this.segmentationName); //Change name of exports
        },

        exportToSvgEmit({width, height}){
            this.exportToSvg(width, height)
        },

        exportToSvg(width, height){
            this.radar.saveAsSvg(
                {
                    "width":width,
                    "height":height,
                    "filename":'SS03_'+this.topicName+'_Market_CAGR_by_'+this.segmentationName,
                }
            )
        },
    },
    watch:{
        // 'data':{
        //     async handler(newVal){
        //         if(newVal){
        //             if(this.chart){
        //                 this.radar.dispose();
        //                 this.chart = null;
        //                 this.radar = null;
        //                 this.init();
        //             }
        //         }
        //     }
        // },
        // 'year':{
        //     async handler(newVal){
        //         if(newVal){
        //             if(this.chart){
        //                 this.radar.dispose();
        //                 this.chart = null;
        //                 this.radar = null;
        //                 this.init();
        //             }
        //         }
        //     },
        //     deep:true,
        // }
    },
    beforeUnmount(){
        if(this.chart){
            this.radar.dispose();
            this.chart = null; 
            this.radar = null; 
        }
    }
}

</script>