<template>
    <div>
        <Button 
            type="button"
            label="export Svg "
            @click="openExportsSettingsModal()" 
        /> 
    </div>

    <Dialog
        v-model:visible="exportsSettingsModal"
        :style="{ width : '50%'}"
        header="Export to Svg (Test Component)"
        :modal="true"
    >
        <div style="display: flex; flex-direction: column; gap: 1rem;"> 
            <div
                style="
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                "
            >
                <div
                    style="
                        display: flex;
                        justify-content: flex-start;
                        align-items: center; 
                        gap: 1rem;   
                    "
                >
                    <label >Width: </label>
                    <InputText
                        type="number"
                        v-model="exportWidth"
                        placeholder="Keyword Search"
                    />
                </div>

                <div
                    style="
                        display: flex;
                        justify-content: flex-start;
                        align-items: center; 
                        gap: 1rem;   
                    "
                >
                    <label > height</label>
                    <InputText
                        type="number"
                        v-model="exportHeight"
                        placeholder="Keyword Search"
                    />
                </div>
                
            </div>
            <Button 
                type="button"
                label="Export Svg "
                @click="exportEmit()" 
            /> 
        </div>
    </Dialog>

</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from "primevue/inputtext";

import './AnychartThemes/market_forecast.js'

export default {
    props: ['algo'],
    emits: ['exportToSvg'],
    name: 'ExportSettingsDialog',
    components: {
        Button,
        Dialog,
        InputText
    },

    data() {
        return {
            exportsSettingsModal:false,
            exportWidth:600,
            exportHeight: 400    
        }
    },
    mounted() {
    },

    computed: {
    },
    methods: {
        openExportsSettingsModal(){
            this.exportsSettingsModal = true
        },
        closeExportsSettingsModal() {
            this.exportsSettingsModal = false; 
        },
        exportEmit(){
            // console.log(this.algo);
            // const exportWidth = this.exportWidth
            // const exportHeight = this.exportHeight
            this.$emit("exportToSvg" , { width: this.exportWidth, height: this.exportHeight })
            this.closeExportsSettingsModal();
        }

    },
    watch: {
    },
    beforeUnmount() {
    }
}
</script>

<style></style>