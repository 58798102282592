<template>
    <div class="container-datas" style="font-size: 1rem; margin-top: 2rem;">
        <div v-if="toChartSegmItems.length > 0">
            <div v-for="(count, countIndex) in toChartSegmItems" :key="countIndex" style="
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    width: 100%;
                ">
                <!-- <h4>
                    <strong>{{ topicName }}</strong> Market Summary by {{ this.segmentationTitles[countIndex][0][0] }}
                    US$ {{ this.valueDisplayOption }}
                </h4> -->

                <!-- 2nd group of graphics -->
                <!-- <h5><strong>{{ topicName }}</strong> Market Forecast to {{ toChartYears[0][toChartYears[0].length - 1]}}
                    by {{ this.segmentationTitles[countIndex][0][0] }} US$ {{ this.valueDisplayOption }} 
                </h5> -->

                <div style="
                        display: grid;
                        grid-template-columns: 1fr;
                        position: relative;
                        gap: 8rem;
                        margin-bottom: 8rem;
                        padding: 0rem 2.5rem 0rem 2.5rem;
                    ">
                    <!-- TABLE 2.1 -->
                    <div style="
                            width: 100%;
                            position: relative;
                        ">
                        <!-- <table style="width: 100%;">
                            <tr class="topicOutputTrTextAlign">
                                <th v-for="(header, headerIndex) in this.headerTable[0]" :key="headerIndex"
                                    class="topicOutputTh">
                                    <div v-if="headerIndex === 0">
                                        {{ this.segmentationTitles[countIndex][0][0] }} US$ {{ this.valueDisplayOption }}
                                    </div>
                                    <div v-html="header"
                                        style="display: flex; justify-content: flex-end; align-items: center;"></div>
                                </th>
                            </tr>
                            <tr v-for="(row, rowIndex) in this.segmentationItemValues[countIndex]" :key="rowIndex"
                                class="topicOutputTrTextAlign">
                                <td v-for="(cell, cellIndex) in row" :key="cellIndex" class="topicOutputTh">
                                    {{ formatNumber(cell) }}
                                </td>
                            </tr>

                            <tr v-for="(total, totalIndex) in this.totals[countIndex]" :key="totalIndex"
                                class="topicOutputTrTextAlign">
                                <td v-for="(cell, cellIndex) in total" :key="cellIndex" class="topicOutputTh">
                                    <div v-if="cellIndex === 0" style=" text-align: right; font-weight: bold; ">
                                        {{ formatNumber(cell) }}
                                    </div>
                                    <div v-else style="font-weight: bold; ">
                                        {{ formatNumber(cell) }}
                                    </div>
                                </td>
                            </tr>
                        </table> -->

                        <ExportTableSingleSegmentation :index1="countIndex"
                            :segmentationTitles="this.segmentationTitles" :headerTable="this.headerTable"
                            :segmentationItemValues="this.segmentationItemValues" :totals="this.totals"
                            :valueDisplayOption="this.valueDisplayOption"
                            :segmentationTitle="this.segmentationTitles[countIndex][0][0]"
                            @storeToVuexTablesForWord="storeToVuexTablesForWord" />
                    </div>

                    <!-- CHART 2.1  -->
                    <div style="
                            display: grid;
                            grid-template-columns: 100%;
                        ">
                        <div style="
                                width: 100%;
                                height: 500px;
                        ">
                            <div style="
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    margin-bottom: 1rem;
                                ">
                                <span style="font-size: 16px;font-weight:bold;">{{ topicName }} Market to {{
                                    toChartYears[0][toChartYears[0].length-1]}} by
                                    {{ segmentationTitles[countIndex][0][0] }}</span>
                                <span style="color:#929292; font-size: 12px;">
                                    Market Forecast {{ toChartYears[0][0] }} - {{ toChartYears[0][toChartYears[0].length - 1
                                    ]}}
                                </span>
                            </div>

                            <VueAnychart 
                                :years="toChartYears[0]" 
                                :data="toChartData[countIndex][0]"
                                :total="totals[countIndex][0]" 
                                :segmentationItems="toChartSegmItems[countIndex]"
                                :output="output" 
                                :segmentationVersusTitles="'Single segmentation Title'"
                                @next-step="nextStep" 
                                :topicName="topicName"
                                :segmentationName="segmentationTitles[countIndex][0][0]"
                                :valueDisplayOption="valueDisplayOption"
                                @addChartsForExportWord="storeToVuexChartsForWord" 
                                :index1="countIndex" 

                            />
                        </div>
                    </div>

                    <!-- CHART 2.2 -->
                    <div style="
                            display: grid;
                            grid-template-columns: 100%;
                        ">
                        <div style="width: 100%; height: 500px;">
                            <div
                                style="display: flex; flex-direction: column; align-items: center; margin-bottom: 1rem;">
                                <span style="font-size: 16px; font-weight: bold;">{{ topicName }} Market Forecast to
                                    {{ this.toFigure3ChartLastYear }} by {{ segmentationTitles[countIndex][0][0] }}</span>
                            </div>
                            <VueAnychartFigure3 
                                :data="this.toFigure3ChartDataYear[countIndex]"
                                :year="this.toFigure3ChartFirstYear" 
                                :firstYear="this.toFigure3ChartFirstYear"
                                :lastYear="this.toFigure3ChartLastYear" 
                                :topicName="topicName"
                                :segmentationName="segmentationTitles[countIndex][0][0]" 
                                @next-step="nextStep"
                                :valueDisplayOption="valueDisplayOption" 
                                :FYValues="toChartData[countIndex][0][0]"
                                :LYValues="toChartData[countIndex][0][toChartData[countIndex][0].length - 1]"
                                @addChartsForExportWord="storeToVuexChartsForWord" 
                                :index1="countIndex" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueAnychart from '../ForecastData/VueAnychart.vue';
import VueAnychartFigure3 from '../ForecastData/VueAnychartFigure3.vue';

import ExportTableSingleSegmentation from './ExportTableSingleSegmentation.vue'


export default {
    name: 'exportForecastDataToWordVersus',
    components: {
        VueAnychart,
        VueAnychartFigure3,

        // export  
        ExportTableSingleSegmentation,
    },
    emits: ['endDataFinish'],
    props: ['output', 'topicName', 'valueDisplayOption', 'decimalsOption'],
    data() {
        return {
            // outputViews for tables
            segmentationTitles: [],
            headerTable: [],
            segmentationItemValues: [],

            // output for Charts
            toChartYears: [],
            toChartData: [],
            toChartSegmItems: [],

            // totals
            totals: [],
            simplifiedTotals: [],

            // Simplified Table
            simplifiedSegmentationTitles: [],
            simplifiedHeaderTable: [],
            simplifiedSegmentationItemValues: [],

            //output For Figure1 Charts
            toFigure1ChartYears: [],
            toFigure1ChartData: [],
            toFigure1SegmItems: [],

            //output For Figure2 Charts
            toFigure2ChartData: [],

            // output for FigureSpider Chart
            toFigureSpiderData: [],

            // output For Figure3 Charts
            toFigure3ChartDataYear: [],
            toFigure3ChartDataFirstYear: [], //remove
            toFigure3ChartDataLastYear: [], //remove

            toFigure3ChartFirstYear: null,
            toFigure3ChartLastYear: null,

            toFigure3TotalFirstYear: null,
            toFigure3TotalLastYear: null,


            // Max Value
            maxValue: [], //for figure 1 taken for all datas 
            maxValueFigure1: [], // for figure taken by datas from figure 1
            // no need for figure 2 cause we get MaxValue with the data we send 

            // sync loading
            loadingStep: 0,  //for charts
            loadingSteps: 6, //for charts
            loadingFinish: false, // LOADING FINISH IS === TRUE WHEN ALL CHARTS ARE COMPLETED RENDERED

            loadingStepTables: 0,
            loadingStepsTables: 3,

            // export To Word
            singleTableToWord: [],
            singleChartToWord: [],
        }
    },

    mounted() {
        // output
        this.assignOutput();
        this.assignOutputToChart();
        this.getTotalRow();
        this.assignOutputToFigure3Chart();

        // finish loading
        // this.finishLoading();
    },
    methods: {
        // emits
        finishLoading() {
            this.$emit("endDataFinish")
        },
        storeToVuexTablesForWord( table ) {
            console.log('chart1');
            this.$store.dispatch('addExportOutputTables', table.exportContent);
        },
        storeToVuexChartsForWord( chart ) {
            console.log('chart2');
            this.$store.dispatch('addExportOutputCharts', chart.exportContent);
        },


        nextStep() {
            this.loadingStep++
        },

        addChartsForExportWord() {
            console.log('');
        },

        assignOutput() {
            //  SHOW ARRAY ['US$M',2022,2023,2024,2025,2026,2027,2028]
            let years = ['']
            this.output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues.map(item => years.push(item.year))
            // ADD TO years [SUM , CAGR] TO SHOW
            //  SHOW ARRAY ['US$M',2022,2023,2024,2025,2026,2027,2028,SUM, CAGR]

            const fyString = years[1].toString()
            const lyString = years[years.length - 1].toString()
            let sumHeader = '∑' + fyString.slice(-2) + '-' + lyString.slice(-2);
            years.push(sumHeader);


            let cagr = 'CAGR <br />' + fyString.slice(-2) + '-' + lyString.slice(-2);
            years.push(cagr);

            this.headerTable.push(years);

            for (let index = 0; index < this.output.length; index++) {
                let titles = [];
                // this.segmentationTitles SHOW MAIN TITLE 
                const segmentationName = this.output[index].segmentations[0].name;
                titles.push([segmentationName]);
                this.segmentationTitles.push(titles)


                const versusValueFinal = []
                this.output[index].segmentations[0].segmentationItems.map(item => {
                    // versusValueFinal IS THE ARRAY THAT SHOW SEGMVERSUSTITLE AND THE MARKETDATA
                    // ['segmentationItem', 1,2,3,4,5,6,7]

                    let segmItemName
                    const versusValue = []
                    if (item.regionId != null) {
                        segmItemName = item.region.name
                    }
                    else {
                        segmItemName = item.name
                    }
                    versusValue.push(segmItemName)

                    item.documentProgramTopic.documentProgramValues.map(
                        item => {
                            // set MaxValue
                            if (!this.maxValue[index]) {
                                this.maxValue[index] = item.value
                            }
                            else {
                                if (item.value > this.maxValue[index]) {
                                    this.maxValue[index] = item.value
                                }
                            }
                            // push datavalue to DataArray
                            versusValue.push(this.tofixValueOptionDecimate(item.value))
                        }
                    )
                    // SUM
                    // sum from frontend
                    let sumArray = [...versusValue]
                    sumArray.shift()
                    let sum = this.toFixDecimate3(sumArray.reduce((acc, val) => acc + parseFloat(val), 0));
                    //Sum from backend
                    // let sum1 = this.tofixValueOptionDecimate(item.documentProgramTopic.sumAll);
                    versusValue.push(sum)

                    // CAGR
                    let cagr
                    if (item.documentProgramTopic.cagr !== null) {  // NULL VALIDATION TODO: ASK 
                        cagr = item.documentProgramTopic.cagr.toFixed(1) + '%';
                    }
                    else {
                        cagr = 0 + '%'
                    }
                    versusValue.push(cagr); // CAGR

                    versusValueFinal.push(versusValue)
                })
                this.segmentationItemValues.push(versusValueFinal)
            }
        },

        assignOutputToChart() {
            this.toChartData = [];
            this.toChartYears = [];

            // outputYears SHOW ARRAY OF YEARS ['2024','2025','2026']
            const outputViews = []
            let years = []
            this.output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues.map(item => years.push(item.year))
            outputViews.push(years)
            this.toChartYears = outputViews;

            // outputData SHOW ARRAY OF ARRAY FOR SHOWING TO CHART
            // 
            // [
            //     [[1,2,3],[4,5,6]],
            //     [[7,8,9],[10,11,12]],
            // ]
            // EXPLAIN
            // [
            //     segment1
            //     [
            //         north America[0,0,0],  europe [0,0,0]
            //     ] , 
            //     segment2
            //     [
            //         north America[0,0,0], europe[0,0,0]
            //     ] , 
            //     segment3
            //     [
            //         north America[0,0,0], europe[0,0,0]
            //     ]
            // ]

            let outputData = []
            for (let index = 0; index < this.output.length; index++) {
                const versusValueFinal = []
                this.output[index].segmentations[0].segmentationItems.map(item => {
                    const versusValue = []

                    item.documentProgramTopic.documentProgramValues.map(
                        item2 => {
                            versusValue.push(this.tofixValueOptionDecimate(item2.value))
                        }
                    )
                    versusValueFinal.push(versusValue)
                })
                outputData.push([versusValueFinal])
            }

            // outputDataFinalle SHOW ARRAY BY YEARS COUNT SHOW VALUE BY COLUMN 
            // [
            //     [
            //         [1,2],  YEAR 2024
            //         [3,4],  YEAR 2025
            //         [segmentationItem1, segmentationItem2]
            //     ],
            //     [
            //         [1,2],  YEAR 2024
            //         [3,4],  YEAR 2025
            //         [segmentationItem1, segmentationItem2]
            //     ],
            //     [
            //         [1,2],  YEAR 2024
            //         [3,4],  YEAR 2025
            //         [segmentationItem1, segmentationItem2]
            //     ]
            // ]


            let outputDataFinalle = []

            outputData.map((output) => {
                let bData = []
                output.map((item) => {
                    let outputArray = []
                    outputViews[0].map((year, index) => {
                        let aData = []
                        item.map(data => {
                            aData.push(data[index])
                        })
                        outputArray.push(aData)
                    })
                    bData.push(outputArray);
                })
                outputDataFinalle.push(bData);
            })

            this.toChartData = outputDataFinalle

            //SegmItemsRival
            // SHOW THE SEGMENTATIONITEMS FOR LEGACY ['SEGMENTATION1','SEGMENTATION2','SEGMENTATION3']

            let Array = [];
            for (let index = 0; index < this.output.length; index++) {
                let SegmItemsRivalNameArray0 = [];
                this.output[index].segmentations[0].segmentationItems.map(item => {
                    if (item.regionId != null) { //for !Region
                        SegmItemsRivalNameArray0.push(item.region.name)
                    }
                    else {
                        SegmItemsRivalNameArray0.push(item.name)
                    }
                })
                Array.push(SegmItemsRivalNameArray0);
            }
            this.toChartSegmItems = Array

        },

        getTotalRow() {
            // CLONE DEEP ARRAY 
            let segmentationItemValuesClone = JSON.parse(JSON.stringify(this.segmentationItemValues));
            //GET TOTAL
            segmentationItemValuesClone.map((segmItem) => {
                let b = []
                segmItem.map((si) => {
                    if (b.length === 0) {
                        let totArray = []
                        si.map((s, index) => {
                            if (index === 0) {
                                totArray.push("Total")
                            } else {
                                if (index !== si.length - 1) {
                                    if (typeof s !== "number") {
                                        let parseToNumber = Number(s);
                                        totArray.push(parseToNumber)
                                    }
                                    else {
                                        totArray.push(s)
                                    }
                                }
                            }
                        })
                        b.push(totArray)
                    }
                    else {
                        si.map((s, index2) => {
                            if (index2 !== 0 && index2 !== si.length - 1) {
                                if (typeof s !== "number") {
                                    let parseToNumber = Number(s);
                                    b[0][index2] += parseToNumber;
                                }
                                else {
                                    b[0][index2] += s;
                                }
                            }
                        })
                    }
                })

                let c = [];
                const d = b[0].map((item, index) => {
                    if (index != 0) {
                        return item = this.toFixDecimate3(item)
                    }
                    else {
                        return item
                    }
                })

                // add CAGR to total row
                const cagrValue = this.calculateCAGR(d).toFixed(1) + '%';

                d.push(cagrValue);

                // add d array to c  
                c.push(d);

                this.totals.push(c);
            })
        },

        assignOutputToFigure3Chart() {
            // To Show PieChart
            // [
            //     {x: "segm1", value:10, value:11},
            //     {x: "segm2", value:12, value:13},
            //     {x: "segm3", value:18, value:19},
            //     {x: "segm4", value:11, value:12},
            //     {x: "segm5", value:9,  value:10},
            // ]

            // To Show PieChart
            // [
            //     ['segm1',FY1,LY2,sum],
            //     ['segm2',FY1,LY2,sum],
            //     ['segm3',FY1,LY2,sum],
            //     ['segm4',FY1,LY2,sum],
            // ]

            for (let index = 0; index < this.output.length; index++) {
                const versusValueFinalYear = [];

                this.output[index].segmentations[0].segmentationItems.map(item => {
                    let segmItemName;

                    if (item.regionId != null) {
                        segmItemName = item.region.name
                    }
                    else {
                        segmItemName = item.name
                    }

                    let valueFirstYear = [
                        segmItemName, //segmName
                        this.tofixValueOptionDecimate(
                            item
                                .documentProgramTopic
                                .documentProgramValues[0]
                                .value
                        ), // FirstYear
                        this.tofixValueOptionDecimate(
                            item
                                .documentProgramTopic
                                .documentProgramValues[item.documentProgramTopic.documentProgramValues.length - 1]
                                .value
                        )  // lastYear
                        //TODO SUM
                    ]
                    versusValueFinalYear.push(valueFirstYear)
                })

                this.toFigure3ChartDataYear.push(versusValueFinalYear)
            }

            this.toFigure3ChartFirstYear = this.output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues[0].year;
            this.toFigure3ChartLastYear = this.output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues[this.output[0].segmentations[0].segmentationItems[0].documentProgramTopic.documentProgramValues.length - 1].year;
        },


        // Shared methods

        convertValueDisplayOption(data) {
            if (this.valueDisplayOption === 'billions') {
                return data / 1000;
            }
            else {
                return data
            }
        },

        toFixDecimate3(cell) {
            if (this.decimalsOption === '0.') {
                if (Number.isInteger(cell)) {
                    return cell
                }
                else {
                    return Math.round(cell);
                }
            }
            if (this.decimalsOption === '.0') {
                return this.roundTo(cell, 1).toFixed(1)
            }
            if (this.decimalsOption === '.00') {
                return this.roundTo(cell, 2).toFixed(2)
            }
            if (this.decimalsOption === '.000') {
                return this.roundTo(cell, 3).toFixed(3)
            }
        },

        tofixValueOptionDecimate(data) {
            const valueDisplay = this.convertValueDisplayOption(data);
            return this.toFixDecimate3(valueDisplay);
        },

        roundTo(num, decimals) {
            let factor = Math.pow(10, decimals);
            return Math.round(num * factor) / factor;
        },

        calculateCAGR(values) {
            let val = [...values]
            val.shift();
            val.pop();

            let initialValue = val[0];
            let finalValue = val[val.length - 1];
            let n = val.length - 1

            const cagr = Math.pow(finalValue / initialValue, 1 / n) - 1;
            return (cagr) ? cagr * 100 : 0
        },

        calculateCAGRSimplified(values) {
            let val = [...values]
            val.shift();
            val.pop();

            let initialValue = val[0];
            let finalValue = val[val.length - 1];
            //  using toFigure1ChartYears for getting  years sub  
            let n = this.toFigure1ChartYears[1] - this.toFigure1ChartYears[0];

            const cagr = Math.pow(finalValue / initialValue, 1 / n) - 1;
            return (cagr) ? cagr * 100 : 0
        },

        formatNumber(num) {
            if (num) {
                // split int and decimals of number
                let [int, decimal] = num.toString().split('.');

                //format int with , 
                int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return decimal ? `${int}.${decimal}` : int
            }
            else {
                return num
            }
        },

        cleanData() {
            // outputViews for tables
            this.segmentationTitles = [];
            this.headerTable = [];
            this.segmentationItemValues = [];

            // output for Charts
            this.toChartYears = [];
            this.toChartData = [];
            this.toChartSegmItems = [];

            // totals
            this.totals = [];
            this.simplifiedTotals = [];

            // Simplified Table
            this.simplifiedSegmentationTitles = [];
            this.simplifiedHeaderTable = [];
            this.simplifiedSegmentationItemValues = [];

            //output For Figure1 Charts
            this.toFigure1ChartYears = [];
            this.toFigure1ChartData = [];
            this.toFigure1SegmItems = [];

            //output For Figure2 Charts
            this.toFigure2ChartData = [];

            // output for FigureSpider Chart
            this.toFigureSpiderData = [];

            // output For Figure3 Charts
            this.toFigure3ChartDataFirstYear = [];
            this.toFigure3ChartDataLastYear = [];

            this.toFigure3ChartFirstYear = null;
            this.toFigure3ChartLastYear = null;

            // Max Value
            this.maxValue = [];//for figure 1 taken for all datas 
            this.maxValueFigure1 = []; // for figure taken by datas from figure 1
            // no need for figure 2 cause we get MaxValue with the data we send 

            // sync loading
            this.loadingStep = 0;
        },


    },

    watch: {
        'output': {
            async handler(newVal) {
                if (newVal) {
                    this.cleanData();

                    this.assignOutput();
                    this.assignOutputToChart();
                    this.getTotalRow();
                    this.assignOutputToFigure3Chart();
                }
            },
            deep: true,
        },
        'valueDisplayOption': {
            async handler(newVal) {
                if (newVal) {
                    this.cleanData();

                    this.assignOutput();
                    this.assignOutputToChart();
                    this.getTotalRow();
                    this.assignOutputToFigure3Chart();
                }
            },
            deep: true,
        },
        'decimalsOption': {
            async handler(newVal) {
                if (newVal) {
                    this.cleanData();

                    this.assignOutput();
                    this.assignOutputToChart();
                    this.getTotalRow();
                    this.assignOutputToFigure3Chart();
                }
            },
            deep: true,
        },
    }
}
</script>

<style scoped>
.topicOutputTrTextAlign>*:not(:first-child) {
    text-align: right;
}

.topicOutputTh {
    border: solid gray 1px;
    padding: 0 1.5% 0 1.5%;

}
</style>