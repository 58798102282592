<template>
    <div v-if="!loading">
        <div class="container">
            <!-- Header -->
            <div class="row">
                <div class="col-12 py-4">
                    <div class="p-fluid container-fluid">
                        <Button class="p-button-raised p-button-rounded my-3 p-button-danger" icon="pi pi-arrow-left"
                            @click="goBack" />
                        <h3><strong>{{ topic.name }}</strong> Export Market Forecast Data</h3>
                    </div>
                </div>
            </div>

            <Divider align="left">
                <div class="inline-flex align-items-center">
                    <span class="p-tag">Export settings</span>
                </div>
            </Divider>
            <!-- InputsFields -->
            <div
                style="
                    margin: 2rem 2rem 2rem 2rem;
                    display: grid;
                    grid-template-columns: 25% 72.5%;
                    grid-template-rows: 1fr;
                    gap: 2.5%;
                "
            >
                <!-- FORM 1 -->
                <div 
                    style="
                    "
                >
                    <div class="export-flexColumn">
                        <label> Select Year(s)</label>

                        <div class="export-flexColumn-contractYear">
                            <Calendar 
                                placeholder="Select First Year" 
                                v-model="firstYear" 
                                view="year" 
                                dateFormat="yy"
                                :showIcon="true" 
                                class="mb-3" 
                                style="width: 50%;" 
                            />
                            <Calendar 
                                placeholder="Select Last Year" 
                                v-model="lastYear" 
                                view="year" 
                                dateFormat="yy"
                                :showIcon="true" 
                                class="mb-3" 
                                style="width: 50%;" 
                            />
                        </div>
                    </div>
                    <div class="export-flexColumn">
                        <label> Scenario</label>
                        <Dropdown 
                            v-model="scenario" 
                            :options="topicScenarios" 
                            optionLabel="name"
                            optionValue="scenarioId" 
                            :showClear="true" 
                            placeholder="Scenario" 
                            class="mb-3"
                        />
                    </div>
                    <div class="export-flexColumn" >
                        <div class="export-flexColumn-valueOptionDecimal">
                            <div class="export-flexColumn-valueOptionDecimal-container">
                                <label> Value display option</label>
                                <!-- <Dropdown style=" width: 100%"></Dropdown> -->
                                <Dropdown 
                                    v-model="valueDisplayOption" 
                                    :options="valueDisplayOptions" 
                                    placeholder="Select Value" 
                                    class="mb-3"
                                    style="width: 100%;"
                                />
                            </div>
                            <div class="export-flexColumn-valueOptionDecimal-container">
                                <label> Decimals</label>
                                <Dropdown 
                                    v-model="decimalsOption" 
                                    :options="decimalsOptions" 
                                    placeholder="Select Decimals" 
                                    class="mb-3"
                                    style="width: 100%;"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- FORM 2 -->
                <div
                    style="
                        width: 100%;
                    "    
                >
                    <label> Segments selection</label>
                    <table
                        style="width: 100%;"
                    >
                        <tr
                            style="
                                padding: 1rem;
                            "
                        >
                            <th 
                                v-for="(segmentation, index) in segmentations" 
                                :key="index"
                                style="
                                    border: 1px solid gray;
                                    padding: 1% 0.5% 1% 2.5%;
                                "
                            >
                                <Checkbox 
                                    v-model="segmentation.checked" 
                                    :binary="true"
                                    @click="checkedSegmentationFunction(segmentation)" 
                                />
                                {{ segmentation.name  }}
                            </th>
                            
                        </tr>
                        <!-- {{ segmentationVersus3 }} -->
                        <tr 
                            v-for="(row, rowIndex) in segmentationVersus3" 
                            :key="rowIndex" 
                            style="
                                padding: 1rem;
                            "
                        >
                            <td 
                                v-for="(cell, cellIndex) in row" 
                                :key="cellIndex"
                                style="
                                    border: 1px solid gray;
                                    padding: 1% 0.5% 1% 2.5%;
                                "
                            >
                                <Checkbox 
                                    v-model="cell.checked" 
                                    :binary="true" 
                                    @click="checkedFunction(cell)" 
                                />
                                {{ cell.name  }}
                            </td>
                        </tr>

                        <!-- HIDE EXPORT TO WORD FUNCTION  -->
                        <!-- TODO: CHANGE THE UI OR OTHER PAGE FOR EXPORT TO WORD -->
                        <!-- <tr
                            style="
                                padding: 1rem;
                            "
                        >
                            <th 
                                v-for="(segmentation, index) in segmentations" 
                                :key="index"
                                style="
                                    border: 1px solid gray;
                                    padding: 1% 0.5% 1% 2.5%;
                                "
                            >
                                <Button label="(in development) Test Export to Word"   @click="exportButton(segmentation)" />
                            </th>
                        </tr> -->
                    </table>
                </div>
            </div>
            <!-- TEST WITH TABLE END -->
            <div  
                style="
                    margin: 2rem 2rem 2rem 2rem;
                    margin-top: 5rem; 
                    display: flex; 
                    gap: 1rem;"
            >   
                <Button 
                    label="Preview tables and Charts" 
                    class=" ml-3"
                    @click="getPreview" 
                    :disabled="this.disableButton || this.v$.firstYear.$invalid || this.v$.lastYear.$invalid || this.v$.scenario.$invalid || checkIfCheckNotSelected() "
                    :loading="loadingPreview" 
                >
                    <label style="margin-right: 0.5rem;"> Preview tables and Charts</label>
                    <font-awesome-icon icon="fa-regular fa-floppy-disk" />
                </Button>
                <Button 
                    label="Export forecast data" 
                    class=" ml-3 p-button-success" 
                    @click="excelDownload"
                    :loading="loadingPreview" 
                >
                    <label style="margin-right: 0.5rem;"> Export forecast data</label>
                    <font-awesome-icon icon="fa-regular fa-floppy-disk-circle-arrow-right" />
                </Button>
            </div>
        </div>


        <!-- Single Segmentation -->
        <div class="container" v-if="singleOutput.length > 0 && decimalsOptionOutput && valueDisplayOptionOutput " style="margin-bottom: 1rem; margin-top: 2rem;" >
            <Divider align="left" >
                <div class="inline-flex align-items-center" style="margin-bottom: 1rem;">
                    <span class="p-tag">Preview Single segmentation tables and charts</span>
                </div>
            </Divider>
            <div v-if="!outputLoadingSingle">
                <ProgramSingleOutputPreview 
                    :output="singleOutput" 
                    :topicName="topic.name" 
                    :valueDisplayOption="valueDisplayOptionOutput"
                    :decimalsOption="decimalsOptionOutput"
                />
            </div>
            <div v-else>
                <div class="col-12 py-4 d-flex justify-content-center">
                    <ProgressSpinner />
                </div>
            </div>
        </div>
        

        <!-- Versus Segmentation -->
        <div class="container" v-if="output.length > 0 " style="margin-bottom: 1rem; margin-top: 2rem;" >
            <Divider align="left" >
                <div class="inline-flex align-items-center" style="margin-bottom: 1rem;">
                    <span class="p-tag">Preview Versus tables and charts</span>
                </div>
            </Divider>
            <div v-if="!outputLoading">
                <ProgramOutputPreview 
                    :output="output" 
                    :topicName="topic.name"  
                    :valueDisplayOption="valueDisplayOptionOutput"
                    :decimalsOption="decimalsOptionOutput"
                />
            </div>
            <div v-else>
                <div class="col-12 py-4 d-flex justify-content-center">
                    <ProgressSpinner />
                </div>
            </div>
        </div>

        <!-- EXPORTS -->
        <div v-if="exportSingleOutput.length > 0 && exportOutput.length > 0">
            <ProgramOutputExportPreviewPage 
                :outputVersus="exportOutput" 
                :outputSingle="exportSingleOutput"
                :topic="topic" 
                :valueDisplayOption="valueDisplayOptionOutputExport"
                :decimalsOption="decimalsOptionOutputExport"
                :exportLastYear="exportLastYear"
                :exportFirstYear="exportFirstYear"
            />
        </div>


    </div>
    <!-- Loading -->
    <div v-else>
        <div class="row">
            <div class="col-12 py-4 d-flex justify-content-center">
                <ProgressSpinner />
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
// import Card from 'primevue/card'
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
// import MultiSelect from 'primevue/multiselect';
import ProgressSpinner from 'primevue/progressspinner';
import Divider from 'primevue/divider';
import Checkbox from 'primevue/checkbox';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
import { exportToExcelOutputValues } from '../../../../topic/services/topic.service'

// import VueAnychart from '../components/VueAnychart.vue';
import ProgramOutputPreview from '../components/ForecastData/ProgramOutputPreview.vue';
import ProgramSingleOutputPreview from '../components/ForecastData/ProgramSingleOutputPreview.vue'

// EXPORT COMPONENTS TODO: MAKE ON A PAGE
import ProgramOutputExportPreviewPage from './ProgramOutputExportPreview.page.vue';



const toast = useToast();
export default {
    name: 'ProgramOutput',
    components: {
        Button,
        // Card,
        Calendar,
        Dropdown,
        // MultiSelect,
        ProgressSpinner,
        Divider,
        Checkbox,
        // VueAnychart,
        ProgramOutputPreview,
        ProgramSingleOutputPreview,
        // EXPORT 
        ProgramOutputExportPreviewPage,
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            outputLoading: false,
            outputLoadingSingle:false,
            loading: true,
            loadingPreview: false,

            firstYear: null,
            lastYear: null,
            scenario: null,
            // 
            segmentations: [],

            // scenarios: [1, 2, 3, 4, 5],
            segmentationVersus: [],
            selectedSegmentationVersus: [],
            // TODO after finish this task replace this on segmentationVersus
            segmentationVersus2: [],
            selectedSegmentationVersus2: [],
            countOfSegmentations: null,
            // segmentatinoVersus3 
            segmentationVersus3 : [],

            //Check
            selectedSegmentationCheck : [],
            selectedCheck: [],
            disableButton: false,


            // Value Display Options
            valueDisplayOptions: [
                'millions',
                'billions',
            ],
            valueDisplayOption: 'millions',
            valueDisplayOptionOutput: null,
            
            // Decimals
            decimalsOptions: [
                '0.','.0','.00','.000'
            ],
            decimalsOption: '0.',
            decimalsOptionOutput: null,


            // FOR EXPORT
            valueDisplayOptionOutputExport: null,
            decimalsOptionOutputExport: null,
            exportFirstYear: null,
            exportLastYear:null,


        }
    },
    validations() {
        return {
            firstYear: { required },
            lastYear: { required },
            scenario: { required },
            // selectedSegmentationVersus: { required }
        }
    },
    async mounted() {
        this.getYears();
        await this.getTopicById(this.$route.params.topicId)
        await this.getTopicVersus(this.$route.params.topicId)
        this.topicVersusList()
        //set Base Scenario
        let baseScenario = this.topicScenarios.find(topicScenario =>{
            return topicScenario.name === "Base Scenario" 
        })
        
        if(baseScenario){
            this.scenario = baseScenario.scenarioId
        }
        else{
            this.scenario = this.topicScenarios[0].scenarioId
        }
    },
    computed: {
        topic() {
            return this.$store.getters.obtainTopic;
        },
        topicScenarios(){
            const topic = this.$store.getters.obtainTopic;
            // return topic.scenarios
            return topic.scenarios.sort((a,b)=> new Date(a.dateCreated) - new Date(b.dateCreated))
        },
        topicVersus() {
            return this.$store.getters.obtainTopicVersus;
        },
        output() {
            return this.$store.getters.obtainOutput;
        },
        singleOutput(){
            return this.$store.getters.obtainSingleOutput;
        },

        exportOutput(){
            return this.$store.getters.obtainExportOutput;
        },
        exportSingleOutput(){
            return this.$store.getters.obtainExportSingleOutput;
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        getYears(){
            // SET YEARS FOR OUTPUT FORM
            const date = new Date(); 
            this.firstYear = date;

            const year = date.getFullYear(); 
            const month = date.getMonth();
            const day = date.getDate();
            const dateLastYear = new Date(year + 8, month, day);
            this.lastYear = dateLastYear
        },

        async getTopicById(topicId) {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainTopic', topicId);
                this.loading = false;
            } catch (error) {
                toast.error(error.message);
                this.loading = false
            }
        },
        async getTopicVersus(topicId) {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainTopicVersus', topicId);
                this.loading = false
            } catch (error) {
                toast.error(error.message);
                this.loading = false
            }
        },
        async getOutput(topicId, form) {
            try {
                this.outputLoading = true;
                await this.$store.dispatch('obtainOutput', { topicId: topicId, form: form });
                this.outputLoading = false
            } catch (error) {
                toast.error(error.message);
                this.outputLoading = false;
            }
        },
        async getSingleOutput( topicId , form ){
            try {
                this.outputLoadingSingle = true; 
                await this.$store.dispatch('obtainSingleOutput',{topicId:topicId, form:form});
                this.outputLoadingSingle = false;
            } catch (error) {
                toast.error(error.message);
                this.outputLoadingSingle = false;
            }
        },
        topicVersusList() {
            const segmentationVersusArray = []
            const segmentationCount = this.topic.segmentations.length // get CountOF Segmentation for order by array 

            // console.log('topicVersus', this.topicVersus);

            this.topicVersus.map((item, index)=> { 
                if(item.segmentations.length === 2){
                    segmentationVersusArray.push(
                        item.segmentations.reduce(
                            (element1,element2)=>{
                                let segmVersus = { 
                                    name: element1.name + ' x ' + element2.name, 
                                    'index': index ,
                                    'checked': false,
                                }
                                return segmVersus
                            }
                        )
                    )
                }
                else{
                    this.segmentations.push({ 
                        ...item.segmentations[0],
                        'checked':false,
                        'index':index
                    })
                }
            });


            // 2 ORDER THE SEGMENTATIONS VERSUS INTO SEGMENTATIONS
            let finalArray = []; // finalArray
            let containerArray = []; // containerArray 
            let segmentXCount = 0;

            for (let index = 0; index < segmentationVersusArray.length; index++) {
                containerArray.push(
                    {
                        ...(segmentationVersusArray[index]),
                    }
                );
                if (segmentXCount === segmentationCount - 2) { // -1 its for the length of the array; -2 its for the versus & the length of the array;  
                    // ADD containerArry to Final Array and set empty to container Array
                    finalArray.push(containerArray);

                    // clean containersArray 
                    containerArray = [];
                    // setcount to 0; 
                    segmentXCount = 0;
                }
                else {
                    segmentXCount += 1;
                }
            }

            // 1
            // console.log('segmentationVersusArray', segmentationVersusArray);
            // this.segmentationVersus = segmentationVersusArray
            // this.selectedSegmentationVersus = segmentationVersusArray

            // 2
            //TODO after finish this task replace on segmentationversus 
            // console.log('finalArray',finalArray);
            this.transformForTable(finalArray); 
            this.segmentationVersus2 = finalArray;
        },
        transformForTable(array){
            // console.log(array); 
            let a = [];

            for (let index = 0; index < array.length; index++) {
                for (let index2 = 0; index2 < array[index].length; index2++) {
                    if(a[index2]){
                        a[index2].push(array[index][index2])
                    }
                    else{
                        let b = []
                        b.push(array[index][index2])
                        a[index2] = b
                    }
                }
            }
            // console.log('a',a);
            this.segmentationVersus3 = a;
        },
        // Checked Segmentation
        checkedSegmentationFunction(value){
            const index = this.selectedSegmentationCheck.indexOf(value);
            if(index !== -1){
                this.selectedSegmentationCheck.splice(index, 1)
            }else{
                this.selectedSegmentationCheck.push(value)
            }
        },

        // CHECKED
        checkedFunction(value) {
            const index = this.selectedCheck.indexOf(value);
            // console.log(index);
            if (index !== -1) {
                this.selectedCheck.splice(index, 1)
            }
            else {
                this.selectedCheck.push(value)
            }
        },

        checkIfCheckNotSelected(){ // this disable button if not checked the table 
            if(this.selectedCheck.length + this.selectedSegmentationCheck.length === 0){
                return true 
            }
            else{
                return false 
            }
        },
        
        async getPreview(){
            this.loadingPreview = true; 
            try {
                const startYear = new Date(this.firstYear).getFullYear()
                const endYear = new Date(this.lastYear).getFullYear()

                let promises = [];

                if(this.selectedCheck.length>0){
                    let Topics = []
                    let segmentationsVersusSorted = [...this.selectedCheck]
                    segmentationsVersusSorted.sort((a,b)=> a.index - b.index);
                    // console.log(segmentationsVersusSorted);

                    segmentationsVersusSorted.map(item => {    
                            const topic = this.topicVersus.filter((element, index) =>
                                index === item.index
                            )
                            Topics.push(topic[0])
                        }
                    )
                    let form = {
                        Topics: Topics,
                        ScenarioId: this.scenario,
                        startYear: startYear,
                        endYear: endYear,
                    }
                    // console.log(form);
                    // await this.getOutput(this.$route.params.topicId, form)
                    // ADD GETPUTPUT TO ARRAY PROMISES
                    promises.push(this.getOutput(this.$route.params.topicId, form));
                }
                else{
                    this.cleanOutput();
                }
                if(this.selectedSegmentationCheck.length > 0){
                    let Topics = []
                    let segmentationSingleSorted = [...this.selectedSegmentationCheck];
                    segmentationSingleSorted.sort((a,b) => a.index - b.index)

                    // console.log('segmentationSingleSorted',segmentationSingleSorted);
                    segmentationSingleSorted.map(item => {
                        const topic = this.topicVersus.filter((element , index) => 
                            index === item.index
                        )
                        Topics.push(topic[0])
                    })
                    // console.log('Topics',Topics);

                    // ToDO add Integration of Rudy
                    let form = {
                        Topics: Topics,
                        ScenarioId: this.scenario,
                        startYear: startYear,
                        endYear: endYear,
                    }
                    // console.log('form',form);
                    // await this.getSingleOutput(this.$route.params.topicId, form) 
                    // ADD GETPUTPUT TO ARRAY PROMISES
                    promises.push(this.getSingleOutput(this.$route.params.topicId, form));
                }
                else{
                    this.cleanSingleOutput(); 
                }
                // EXECT ALL PROMISES
                await Promise.all(promises);

                // set ValueDisplayOptionOutput; 
                this.valueDisplayOptionOutput = this.valueDisplayOption
                // set decimalsOptionOutput; 
                this.decimalsOptionOutput = this.decimalsOption
                this.loadingPreview = false; 

            } catch (error) {
                console.log(error);
                this.loadingPreview = false; 
            }
        },

        async excelDownload() {
            this.loadingPreview =true;
            try {
                const startYear = new Date(this.firstYear).getFullYear()
                const endYear = new Date(this.lastYear).getFullYear()
                let Topics = []

                const a = [...this.segmentationVersus2] ;
                const ab = a.flat();
                const c = []
                // const c = [...this.segmentations]
                const lastarray = [...ab, ...c];

                const filteredArray =  lastarray.filter(item => item.checked === true)

                // TODO comment this
                // this.selectedSegmentationVersus2.map(item => {
                //     const topic = this.topicVersus.filter((element, index) =>
                //         index === item.index
                //     )
                //     Topics.push(topic[0])
                // })

                // Final Solution 
                if(filteredArray.length>0){
                    filteredArray.map(item => {
                        const topic = this.topicVersus.filter((element, index) =>
                            index === item.index
                        )
                        Topics.push(topic[0])
                    })
                }
                else{
                    // this.topicVersus.map(item => {
                    //     Topics.push(item)
                    // })

                    lastarray.map(item => {
                        const topic = this.topicVersus.filter((element, index) =>
                            index === item.index
                        )
                        Topics.push(topic[0])
                    })
                }
                
                let form = {
                    Topics: Topics,
                    ScenarioId: this.scenario,
                    startYear: startYear,
                    endYear: endYear,
                }

                // console.log('form',form);
                // Todo commentThis 
                // await this.getOutput(this.$route.params.topicId, form)
                await exportToExcelOutputValues(
                    this.$route.params.topicId, 
                    form, 
                    this.topic.name, 
                    // this.valueDisplayOption
                )

                // this.$router.push('./preview')

                this.loadingPreview =false;
                
            } catch (error) {
                console.log(error);
                this.loadingPreview =false;
            }
        },

        //CLean topicVersus
        cleanOutput(){
            this.$store.dispatch('cleanOutput');
        },
        // Clean SingleSegmentation 
        cleanSingleOutput(){
            this.$store.dispatch('cleanSingleOutput');
        },
        // Export Button 
        // export By segment

        async exportButton(segmentation){
            let singleSegmentationArray = []
            let versusSegmentationArray = []

            const startYear = new Date(this.firstYear).getFullYear()
            const endYear = new Date(this.lastYear).getFullYear()

            this.exportFirstYear = startYear
            this.exportLastYear = endYear

            this.topicVersus.forEach(elements => {
                if(elements.segmentations[0].segmentationId === segmentation.segmentationId ){
                    if(elements.segmentations.length === 1 ){
                        singleSegmentationArray.push(elements);
                    }
                    else{
                        versusSegmentationArray.push(elements);
                    }
                }
            });

            let formSingle = {
                Topics: singleSegmentationArray,
                ScenarioId: this.scenario,
                startYear: startYear,
                endYear: endYear,
            }

            let formVersus = {
                Topics: versusSegmentationArray,
                ScenarioId: this.scenario,
                startYear: startYear,
                endYear: endYear,
            }
            
            // TODO CREATE NEW VALUE
            // SET VALUEDISPLAYOPTIONOUTPUT
            this.valueDisplayOptionOutputExport = this.valueDisplayOption
            // SET DECIMALSOPTIONOUTPUT
            this.decimalsOptionOutputExport = this.decimalsOption

            // await this.getExportOutput(this.$route.params.topicId, formVersus)
            // await this.getExportSingleOutput(this.$route.params.topicId, formSingle)

            let promises = [] 
            promises.push(this.getExportOutput(this.$route.params.topicId, formVersus))
            promises.push(this.getExportSingleOutput(this.$route.params.topicId, formSingle))
            await Promise.all(promises)
            
        },

        async getExportOutput(topicId, form) {
            try {
                this.outputLoading = true;
                await this.$store.dispatch('obtainExportOutput', { topicId: topicId, form: form });
                this.outputLoading = false
            } catch (error) {
                toast.error(error.message);
                this.outputLoading = false;
            }
        },
        async getExportSingleOutput( topicId , form ){
            try {
                this.outputLoading = true; 
                await this.$store.dispatch('obtainExportSingleOutput',{topicId:topicId, form:form});
                this.outputLoading = false;
            } catch (error) {
                toast.error(error.message);
                this.outputLoading = false;
            }
        },



    },
    watch: {
        'selectedCheck': {
            async handler(newVal) {
                if (newVal.length + this.selectedSegmentationCheck.length > 4 )  {
                    this.disableButton = true
                }
                else {
                    if (this.disableButton) {
                        this.disableButton = false
                        // console.log('habilitarButton');
                    }
                }
            },
            deep: true,
        },
        'selectedSegmentationCheck': {
            async handler(newVal) {
                if (newVal.length + this.selectedCheck.length > 4 )  {
                    this.disableButton = true
                }
                else {
                    if (this.disableButton) {
                        this.disableButton = false
                        // console.log('habilitarButton');
                    }
                }
            },
            deep: true,
        },

    },
    beforeUnmount(){
        this.cleanOutput();
        this.cleanSingleOutput();
    }
}
</script>

<style scoped>

.export-flexColumn {
    display: flex;
    flex-direction: column;
}

.export-flexColumn-contractYear {
    display: flex;
    gap: 1rem;
}
.export-flexColumn-valueOptionDecimal{
    display: flex;
    gap: 1rem;
    width: 100%;
}

.export-flexColumn-valueOptionDecimal-container{
    width: 100%;
}

</style>