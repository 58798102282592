<template>
    <div class="container-datas" style="font-size: 1rem; margin-top: 2rem;">
        <div v-if="toChartSegmItems.length > 0">

            <div v-for="(count, countIndex) in toChartSegmItems" :key="countIndex" style="
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    width: 100%; 
                ">
                <h4>
                    <strong>{{ topicName }}</strong> Market Forecast to {{ toChartYears[0][toChartYears[0].length - 1] }}
                    {{ this.segmentationVersusTitles[countIndex][0][0] }} US$ {{ this.valueDisplayOption }}
                </h4>
                <div v-for="(charts, chartIndex) in count" :key="chartIndex" style="
                        width: 100%;
                        height: 150%;
                        margin-bottom: 5rem;
                    ">
                    <div style="
                            display: grid;
                            grid-template-columns: 1fr;
                            position: relative;
                            gap: 8rem;
                            margin-bottom: 5rem;
                            padding: 0rem 2.5rem 0rem 2.5rem;
                        ">
                        <!-- Tables -->
                        <div style="
                            width: 100%;
                            position: relative;
                        ">
                            
                            <!-- ['index', 'index1', 'SegmentationVersusTitles', 'segmentationTitles', 'headerTable', 'segmentationItemValues', 'totals', 'valueDisplayOption'], -->
                            <ExportTableVersusSegmentation 
                                :index="chartIndex"
                                :index1="countIndex"
                                :segmentationVersusTitles="this.segmentationVersusTitles"
                                :segmentationTitles="this.segmentationTitles"
                                :headerTable="this.headerTable"
                                :segmentationItemValues="this.segmentationItemValues"
                                :totals="totals"
                                :valueDisplayOption="valueDisplayOption"
                                :segmentationName1="this.segmentation1[countIndex]"
                                :segmentationName2="this.segmentation2[countIndex]"
                                @storeToVuexTablesForWord="storeToVuexTablesForWord" 
                            />

                        </div>
                        <!-- Charts -->
                        <div>
                            <div
                                style="
                                    width: 100%;
                                    height: 500px;
                                "
                            >
                                <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 1rem;">
                                    <span style="font-size: 16px;font-weight:bold;">
                                        {{this.segmentationTitles[countIndex][chartIndex]}} Market Forecast to {{ toChartYears[0][toChartYears[0].length -1 ]}} by {{this.segmentation2[countIndex]}}
                                    </span>
                                    <span style="color:#929292; font-size: 12px;">
                                        Market Forecast {{toChartYears[0][0]}} - {{toChartYears[0][toChartYears[0].length - 1]}}
                                    </span>
                                </div>

                                <VueAnychartVersus
                                    :years="toChartYears[0]" 
                                    :data="toChartData[countIndex][chartIndex]"
                                    :total="totals[countIndex][chartIndex][0]"
                                    :segmentationItems="toChartSegmItems[countIndex][chartIndex]" 
                                    :output="output"
                                    :segmentationVersusTitles="this.segmentationVersusTitles[countIndex][0]"
                                    
                                    @next-step="nextStep"
                                    :topicName="topicName"
                                    :valueDisplayOption="valueDisplayOption"
                                    :segmentationName1="this.segmentation1[countIndex]"
                                    :segmentationName2="this.segmentation2[countIndex]"
                                    :segmentation1ItemsName="this.segmentationTitles[countIndex][chartIndex]"
                                    
                                    :exportToWord="true"
                                    @addChartsForExportWord="storeToVuexChartsForWord"
                                    :index1="countIndex"

                                    :toChartSegmItemsLength="toChartSegmItems.length"
                                    :countLength="count.length"
                                    :chartIndex="chartIndex"
                                    @finishLoading="finishLoading"

                                >

                                </VueAnychartVersus>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueAnychartVersus from '../ForecastData/VueAnychartVersus.vue';
// import ProgressSpinner from 'primevue/progressspinner';

import ExportTableVersusSegmentation from './ExportTableVersusSegmentation.vue';

export default {
    name: 'exportForecastDataToWordVersus',
    components: {
        VueAnychartVersus,
        // ProgressSpinner,
        ExportTableVersusSegmentation,
    },
    emits: ['endDataFinish'],
    props: ['output', 'topicName', 'valueDisplayOption', 'decimalsOption'],
    data() {
        return {
            // outputViews for tables
            segmentationVersusTitles: [],
            segmentationTitles: [],
            headerTable: [],
            segmentationItemValues: [],
            segmentation1: [],
            segmentation2: [],
            segmentation1Items: [],

            // OUTPUT FOR CHARTS
            toChartYears: [],
            toChartData: [],
            toChartSegmItems: [],

            totals: [],

            // Sync loading
            loadingStep: 0,
            loadingSteps: 1,
        }
    },
    mounted() {
        // output
        this.assignOutput();
        this.assignOutputToChart();
        this.getTotalRow();

        // finish loading to the last Charts
        // this.finishLoading();
    },
    methods: {
        nextStep(){
            this.loadingStep++
        },

        finishLoading() {
            this.$emit("endDataFinish")
        },
        storeToVuexTablesForWord( table ){
            // console.log('storeToVuexTablesForWord');
            this.$store.dispatch('addExportOutputVersusTables', table.exportContent);
        },
        storeToVuexChartsForWord( chart ){
            this.$store.dispatch('addExportOutputVersusCharts', chart.exportContent);
        },

        assignOutput() {
            //  SHOW ARRAY ['US$M',2022,2023,2024,2025,2026,2027,2028]
            // let years = ['US$M']
            let years = ['']
            this.output[0].segmentations[0].segmentationItems[0].inverseParent[0].documentProgramTopic.documentProgramValues.map(item => years.push(item.year))

            // ADD TO years [SUM , CAGR] TO SHOW
            //  SHOW ARRAY ['US$M',2022,2023,2024,2025,2026,2027,2028,SUM, CAGR]

            const fyString = years[1].toString()
            const lyString = years[years.length - 1].toString()

            let sumHeader = '∑' + fyString.slice(-2) + '-' + lyString.slice(-2);
            years.push(sumHeader);


            let cagr = 'CAGR <br />' + fyString.slice(-2) + '-' + lyString.slice(-2);
            years.push(cagr);

            this.headerTable.push(years);

            let segItemNameGroupLast = []

            for (let index = 0; index < this.output.length; index++) {
                let titles = [];

                // this.SegmentationVersusTitles SHOW VERSUS TITLE ['SEGMENTATION X SEGMENTATION']
                const segmentationName = this.output[index].segmentations[0].name
                const segmentationName2 = this.output[index].segmentations[1].name

                titles.push([segmentationName + ' - ' + segmentationName2]);
                this.segmentationVersusTitles.push(titles);

                this.segmentation1.push(segmentationName)
                this.segmentation2.push(segmentationName2)

                // versusValueFInal

                const versusValueFinal = []
                let SegmItemNameGroup = []
                this.output[index].segmentations[0].segmentationItems.map(item => {
                    // this.segmentationTitles SHOW THE SUBTITLE ['SEGMENTATION']
                    let segmItemName
                    if (item.regionId != null) {
                        segmItemName = item.region.name
                    }
                    else {
                        segmItemName = item.name
                    }
                    SegmItemNameGroup.push(segmItemName)
                    // versusValueFinal IS THE ARRAY THAT SHOW SEGMVERSUSTITLE AND THE MARKETDATA
                    // ['segmentationItem', 1,2,3,4,5,6,7]

                    const versusValue = []
                    item.inverseParent.map(item2 => {
                        const versusValue2 = []
                        // change RegionId to regionName
                        if (item2.regionId != null) { //for !region
                            versusValue2.push(item2.region.name)
                        }
                        else {
                            versusValue2.push(item2.name)
                        }

                        item2.documentProgramTopic.documentProgramValues.map(
                            item3 => {
                                versusValue2.push(this.tofixValueOptionDecimate(item3.value))
                            }
                        )
                        // SUM 
                        // get the sum on front
                        let sumArray = [...versusValue2]
                        sumArray.shift()
                        let sum = this.toFixDecimate3(sumArray.reduce((acc, val) => acc + parseFloat(val), 0));

                        // sum from backend  
                        // let sum = this.tofixValueOptionDecimate(item2.documentProgramTopic.sumAll)
                        versusValue2.push(sum)


                        // CAGR 
                        let cagr = (item2.documentProgramTopic.cagr) ? item2.documentProgramTopic.cagr.toFixed(1) + '%' : 0 + '%'
                        versusValue2.push(cagr) // CAGR

                        versusValue.push(versusValue2)
                    })
                    versusValueFinal.push(versusValue)
                })
                segItemNameGroupLast.push(SegmItemNameGroup);
                // this.segmentationItemValues IS THE ARRAY THAT SHOW SEGMVERSUSTITLE AND THE MARKETDATA
                this.segmentationItemValues.push(versusValueFinal)
            }
            this.segmentationTitles = segItemNameGroupLast
        },

        assignOutputToChart() {
            this.toChartData = [];
            this.toChartYears = [];

            // outputYears SHOW ARRAY OF YEARS ['2024','2025','2026']
            const outputViews = []
            let years = []
            this.output[0].segmentations[0].segmentationItems[0].inverseParent[0].documentProgramTopic.documentProgramValues.map(item => years.push(item.year))
            outputViews.push(years)
            this.toChartYears = outputViews;


            // outputData SHOW ARRAY OF ARRAY FOR SHOWING TO CHART
            // 
            // [
            //     [[1,2,3],[4,5,6]],
            //     [[7,8,9],[10,11,12]],
            // ]
            // EXPLAIN
            // [
            //     segment1
            //     [
            //         north America[0,0,0],  europe [0,0,0]
            //     ] , 
            //     segment2
            //     [
            //         north America[0,0,0], europe[0,0,0]
            //     ] , 
            //     segment3
            //     [
            //         north America[0,0,0], europe[0,0,0]
            //     ]
            // ]

            let outputData = []
            for (let index = 0; index < this.output.length; index++) {
                const versusValueFinal = []
                this.output[index].segmentations[0].segmentationItems.map(item => {
                    const versusValue = []

                    item.inverseParent.map(item2 => {
                        const versusValue2 = []
                        item2.documentProgramTopic.documentProgramValues.map(
                            item3 => {
                                versusValue2.push(this.tofixValueOptionDecimate(item3.value))
                            }
                        )
                        versusValue.push(versusValue2)
                    })
                    versusValueFinal.push(versusValue)
                })
                outputData.push(versusValueFinal)
            }
            // outputDataFinalle SHOW ARRAY BY YEARS COUNT SHOW VALUE BY COLUMN 
            // [
            //     [
            //         [1,2],  YEAR 2024
            //         [3,4],  YEAR 2025
            //         [segmentationItem1, segmentationItem2]
            //     ],
            //     [
            //         [1,2],  YEAR 2024
            //         [3,4],  YEAR 2025
            //         [segmentationItem1, segmentationItem2]
            //     ],
            //     [
            //         [1,2],  YEAR 2024
            //         [3,4],  YEAR 2025
            //         [segmentationItem1, segmentationItem2]
            //     ]
            // ]

            // set the data to years 
            let outputDataFinalle = []

            outputData.map((output) => {
                let bData = []
                output.map((item) => {
                    let outputArray = []
                    outputViews[0].map((year, index) => {
                        let aData = []
                        item.map(data => {
                            aData.push(data[index])
                        })
                        outputArray.push(aData)
                    })
                    bData.push(outputArray);
                })
                outputDataFinalle.push(bData);
            })

            this.toChartData = outputDataFinalle

            //SegmItemsRival
            // SHOW THE SEGMENTATIONITEMS FOR LEGACY ['SEGMENTATION1','SEGMENTATION2','SEGMENTATION3']

            let Array = [];
            for (let index = 0; index < this.output.length; index++) {
                let SegmItemsRivalNameArray0 = [];
                this.output[index].segmentations[0].segmentationItems.map(item => {
                    const SegmItemsRivalNameArray = [];
                    item.inverseParent.map(item2 => {
                        let versusValue2
                        // change RegionId to regionName
                        if (item2.regionId != null) { //for !region
                            versusValue2 = item2.region.name
                        }
                        else {
                            versusValue2 = item2.name
                        }
                        SegmItemsRivalNameArray.push(versusValue2)
                    })
                    SegmItemsRivalNameArray0.push(SegmItemsRivalNameArray);
                })
                Array.push(SegmItemsRivalNameArray0);
            }
            this.toChartSegmItems = Array
        },

        getTotalRow() {
            // CLONE DEEP ARRAY 
            let segmentationItemValuesClone = JSON.parse(JSON.stringify(this.segmentationItemValues));

            //GET TOTAL
            segmentationItemValuesClone.map(siv => {
                let a = []
                siv.map((segmItem) => {
                    let b = []
                    segmItem.map((si) => {
                        if (b.length === 0) {
                            let totArray = []
                            si.map((s, index) => {
                                if (index === 0) {
                                    totArray.push("Total")
                                }
                                else {
                                    if (index !== si.length - 1) {
                                        if (typeof s !== 'number') {
                                            let parseToNumber = Number(s);
                                            totArray.push(parseToNumber)
                                        }
                                        else {
                                            totArray.push(s)
                                        }
                                    }
                                }
                            })
                            b.push(totArray)
                        }
                        else {
                            si.map((s, index2) => {
                                if (index2 !== 0 && index2 !== si.length - 1) {
                                    if (typeof s !== "number") {
                                        let parseToNumber = Number(s);
                                        b[0][index2] += parseToNumber;
                                    }
                                    else {
                                        b[0][index2] += s;
                                    }
                                }
                            })
                        }
                    })
                    // transform data to fixed decimate 
                    let c = []
                    const d = b[0].map((item, index) => {
                        if (index != 0) {
                            return item = this.toFixDecimate3(item)
                        }
                        else {
                            return item
                        }
                    })

                    // add cagr to total row 
                    const cagrValue = this.calculateCAGR(d).toFixed(1) + '%';
                    d.push(cagrValue)

                    // Add D array to c
                    c.push(d)

                    //push to a array 
                    a.push(c)
                })
                this.totals.push(a);
            })
        },

        cleanData() {
            // Todo this is for hardCoded Test for charts
            this.toChartYears = []
            this.toChartData = []
            this.toChartSegmItems = []

            // outputViews
            this.headerTable = []
            this.segmentationVersusTitles = []
            this.segmentationTitles = []
            this.segmentationItemValues = []

            //Totals
            this.totals = []
        },

        // SHARED 
        calculateCAGR(values) {
            let val = [...values]
            val.shift();
            val.pop()

            let initialValue = val[0]
            let finalValue = val[val.length - 1];
            let n = val.length - 1

            const cagr = Math.pow(finalValue / initialValue, 1 / n) - 1;
            return (cagr) ? cagr * 100 : 0
        },

        convertValueDisplayOption(data) {
            if (this.valueDisplayOption === 'billions') {
                return data / 1000;
            }
            else {
                return data
            }
        },

        toFixDecimate3(cell) {
            if (this.decimalsOption === '0.') {
                if (Number.isInteger(cell)) {
                    return cell
                }
                else {
                    return Math.round(cell);
                }
            }
            if (this.decimalsOption === '.0') {
                return this.roundTo(cell, 1).toFixed(1);
            }
            if (this.decimalsOption === '.00') {
                return this.roundTo(cell, 2).toFixed(2);
            }
            if (this.decimalsOption === '.000') {
                return this.roundTo(cell, 3).toFixed(3);
            }
        },

        tofixValueOptionDecimate(data) {
            const valueDisplay = this.convertValueDisplayOption(data);
            return this.toFixDecimate3(valueDisplay);
        },

        roundTo(num, decimals) {
            let factor = Math.pow(10, decimals);
            return Math.round(num * factor) / factor;
        },

        formatNumber(num) {
            if (num) {
                // split int and decimals of number
                let [int, decimal] = num.toString().split('.');
                //format int with , 
                int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return decimal ? `${int}.${decimal}` : int
            }
            else {
                return num
            }
        },
    },

    watch: {
        'output': {
            async handler(newVal) {
                if (newVal) {
                    this.cleanData();

                    this.assignOutput();
                    this.assignOutputToChart();
                    this.getTotalRow();

                }
            },
            deep: true,
        },

        'valueDisplayOption': {
            async handler(newVal) {
                if (newVal) {
                    this.cleanData();

                    this.assignOutput();
                    this.assignOutputToChart()
                    this.getTotalRow();

                }
            },
            deep: true,
        },

        'decimalsOption': {
            async handler(newVal) {
                if (newVal) {
                    this.cleanData();

                    this.assignOutput();
                    this.assignOutputToChart()
                    this.getTotalRow();

                }
            },
            deep: true,
        }

    }
}
</script>

<style scoped>
.topicOutputTrTextAlign>*:not(:first-child) {
    text-align: right;
}

.topicOutputTh {
    border: solid gray 1px;
    padding: 0 1.5% 0 1.5%;

}
</style>