<template>
    <div ref="exportContentVersus">
        <table 
            style="
                width: 100%;
            "
        >
            <!-- TableTitle -->
            <tr>
                <th v-for="(header, index2 ) in this.headerTable[0]" :key="index2" style="
                        background-color: #3f5f8f;
                        border-collapse: collapse;
                        border: 1px solid #cad8ef;
                        /* padding: 2px; */
                        font-family: Arial;
                        font-size: 12px;
                        text-align: center;
                        color: #FFFFFF;
                        /* vertical-align: top; */
                    ">
                    <div v-if="index2 === 0">
                        <!-- {{ this.segmentationTitles[index1][index] }} US$ {{this.valueDisplayOption}} -->
                    </div>
                    <div v-html="header"></div>
                </th>
            </tr>
            <!-- Data -->
            <tr v-for="(row, rowIndex) in this.segmentationItemValues[index1][index]" :key="rowIndex">
                <td v-for="(cell, cellIndex) in row" :key="cellIndex" style="
                        border-collapse: collapse;
                        border: 1px solid #cad8ef;
                        /* padding: 2px; */
                        font-family: Arial;
                        font-size: 11px;
                        text-align: right;
                    ">
                    <div v-if="cellIndex === 0" style="text-align: left;">
                        {{ formatNumber(cell) }}
                    </div>
                    <div v-else>
                        {{ formatNumber(cell) }}
                    </div>
                </td>
            </tr>
            <!-- Total -->
            <tr v-for="(total, totalIndex) in this.totals[index1][index]" :key="totalIndex" style="
                    border-collapse: collapse;
                    border: 1px solid #cad8ef;
                    /* padding: 2px; */
                    font-family: Arial;
                    font-size: 11px;
                    text-align: right;
                ">
                <td v-for="(cell, cellIndex) in total" :key="cellIndex">
                    <div style="font-weight: bold;">
                        {{ formatNumber(cell) }}
                    </div>
                </td>
            </tr>

        </table>
    </div>
</template>
<script>
export default {
    name: 'ExportTableVersusSegmentation',
    emits: ['storeToVuexTablesForWord'],
    props: [
        'index', 
        'index1', 
        'segmentationVersusTitles', 
        'segmentationTitles', 
        'headerTable', 
        'segmentationItemValues', 
        'totals', 
        'valueDisplayOption',
        
        'segmentationName1',
        'segmentationName2',
    ],
    components: {
    },
    data() {
        return {};
    },
    mounted() {
        this.addTablesForWord();
    },
    methods: {
        formatNumber(num) {
            if (num) {
                // split int and decimals of number
                let [int, decimal] = num.toString().split('.');

                //format int with , 
                int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                return decimal ? `${int}.${decimal}` : int
            }
            else {
                return num
            }
        },

        addTablesForWord() {
            const exportContent = {
                content: this.$refs.exportContentVersus.innerHTML,
                name: 'versus Segmentation' + ' table2.1',
                segmentation1: this.segmentationName1,
                segmentation2: this.segmentationName2,
                segmentationItem: this.segmentationTitles[this.index1][this.index],
                order: this.index1
            };
            this.$emit("storeToVuexTablesForWord", { exportContent, order: exportContent.order })
        },
    }
}
</script>
<style></style>