<template>
    <div ref="exportContent">
        <table style="
                    width: 100%;
                " :id="index1 + 'table'">
            <!-- TableTitle -->
            <tr>
                <th 
                    v-for="(header, index ) in this.headerTable[0]" 
                    :key="index" 
                    style="
                        background-color: #3f5f8f;
                        border-collapse: collapse;
                        border: 1px solid #cad8ef;
                        /* padding: 2px; */
                        font-family: Arial;
                        font-size: 12px;
                        text-align: center;
                        color: #FFFFFF;
                        /* vertical-align: top; */
                    ">
                        <div v-if="index === 0">
                        </div>
                        <div v-html="header"></div>
                </th>
            </tr>
            <!-- Data -->
            <tr v-for="(row, rowIndex) in this.segmentationItemValues[index1]" :key="rowIndex">
                <td v-for="(cell, cellIndex) in row" :key="cellIndex" 
                    style="
                        border-collapse: collapse;
                        border: 1px solid #cad8ef;
                        /* padding: 2px; */
                        font-family: Arial;
                        font-size: 11px;
                        text-align: right;
                    ">
                    <div v-if="cellIndex === 0" style="text-align: left;" >
                        {{ formatNumber(cell) }}
                    </div>
                    <div v-else>
                        {{ formatNumber(cell) }}
                    </div>
                </td>
            </tr>
            <!-- Total -->
            <tr v-for="(total, totalIndex) in this.totals[index1]" :key="totalIndex">
                <td 
                    v-for="(cell, cellIndex) in total" 
                    :key="cellIndex"
                    style="
                        border-collapse: collapse;
                        border: 1px solid #cad8ef;
                        /* padding: 2px; */
                        font-family: Arial;
                        font-size: 11px;
                        text-align: right;
                    "
                >
                    <div style="font-weight: bold;">
                        {{ formatNumber(cell) }}
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    name: 'ExportTableSingleSegmentation',
    emits: ['storeToVuexTablesForWord'],
    props: [
        'index1',
        'segmentationTitles',
        'headerTable',
        'segmentationItemValues',
        'totals',
        'valueDisplayOption',
        'segmentationTitle'
    ],

    components: {
    },

    data() {
        return {
            alwaysFalse: true,
        };
    },

    mounted() {
        this.addTablesForWord();
    },

    methods: {
        copyToClipBoard(index1) {
            // ON CopyTableCountryData.vue WE IMPROVE THE CHART TO HIDE OFF THE SCREEN IF NEED CHANGE THIS LOGIC

            var range = document.createRange();
            let containerNode = document.getElementById(index1 + 'table'); //// this part
            // console.log(containerNode);
            containerNode.style.visibility = 'visible';

            range.selectNode(containerNode); //// this part
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
            containerNode.style.visibility = 'hidden';
            alert("data copied");

        },

        formatNumber(num) {
            if (num) {
                // split int and decimals of number
                let [int, decimal] = num.toString().split('.');

                //format int with , 
                int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                return decimal ? `${int}.${decimal}` : int
            }
            else {
                return num
            }
        },

        addTablesForWord() {
            const exportContent = {
                content: this.$refs.exportContent.innerHTML,
                name: 'singleSegmentation' + ' table2.1',
                segmentation: this.segmentationTitle,
                order: this.index1
            };
            this.$emit("storeToVuexTablesForWord", { exportContent, order: exportContent.order })
        },
    }
};
</script>

<style scoped>
/* styles class are on CopyTableSingleSegmentationSimplified */

.CopyTable-exportTable th {
    background-color: #3f5f8f;
    border-collapse: collapse;
    border: 1px solid #cad8ef;
    padding: 2px;
    font-family: Arial;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
    vertical-align: top;
}

.CopyTable-exportTable td {
    border-collapse: collapse;
    border: 1px solid #cad8ef;
    padding: 2px;
    font-family: Arial;
    font-size: 11px;
    text-align: right;
}

.CopyTable-exportTable .CopyTable-exportTable-rowDesc {
    text-align: left;
}

.CopyTable-exportTable .CopyTable-exportTable-total {
    font-weight: bold;
}
</style>
