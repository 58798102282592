<template>
    <div style="
            position: absolute; 
            top: 110%; 
            left: -0%;
        ">
        <Button @click="copyToClipBoard(index1)">
            Copy Table
        </Button>
        <!-- <Button @click="exportToWord()">
            Export To Word
        </Button> -->

        <div ref="exportContent">
            <table 
                style="
                    visibility: hidden;
                " 
                class="CopyTable-exportTable"
                :id="index1 + 'tableSimplified'"
            >
                <!-- headerTable -->
                <tr>
                    <th 
                        v-for="(header, index) in this.simplifiedHeaderTable[0]" 
                        :key="index"
                        style="
                            background-color: #3f5f8f;
                            border-collapse: collapse;
                            border: 1px solid #cad8ef;
                            padding: 2px;
                            font-family: Arial;
                            font-size: 12px;
                            text-align: center;
                            color: #FFFFFF;
                            vertical-align: top;
                        "
                    >
                        <div v-if="index === 0">
                            <!-- {{ this.segmentationTitles[index1][0][0] }} US$ {{this.valueDisplayOption}} -->
                        </div>
                        <div v-html="header"></div>
                    </th>
                </tr>
                <!-- Data -->
                <tr v-for="(row, rowIndex) in this.simplifiedSegmentationItemValues[index1]" :key="rowIndex"
                    >
                    <td 
                        v-for="(cell, cellIndex) in row" 
                        :key="cellIndex"
                        style="
                            border-collapse: collapse;
                            border: 1px solid #cad8ef;
                            padding: 2px;
                            font-family: Arial;
                            font-size: 11px;
                            text-align: right;
                        ;"
                    >
                        <div 
                            v-if="cellIndex === 0" 
                            class="CopyTable-exportTable-rowDesc" 
                            style="text-align: left;"
                        >
                            {{ formatNumber(cell) }}
                        </div>
                        <div v-else>
                            {{ formatNumber(cell) }}
                        </div>
                        
                        <!-- {{cell}} -->
                    </td>
                </tr>
                <!-- Total -->
                <!-- {{ totals[index1] }} -->
                <tr v-for="(total, totalIndex) in this.simplifiedTotals[index1]" :key="totalIndex">
                    <td 
                        v-for="(cell, cellIndex) in total" 
                        :key="cellIndex"
                        style="
                            border-collapse: collapse;
                            border: 1px solid #cad8ef;
                            padding: 2px;
                            font-family: Arial;
                            font-size: 11px;
                            text-align: right;
                            font-weight: bold;
                        ;"
                    >
                        <div 
                            v-if="cellIndex === 0" 
                            class="CopyTable-exportTable-total" 
                            style="text-align: left;"
                        >
                            {{ formatNumber(cell) }}
                        </div>
                        <div v-else>
                            {{ formatNumber(cell) }}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';

export default {
    name: 'CopyTableSingleSegmentationSimplified',
    emits: [],
    props: ['index1', 'segmentationTitles', 'simplifiedHeaderTable', 'simplifiedSegmentationItemValues', 'simplifiedTotals','valueDisplayOption'],

    components: {
        Button
    },

    data() {
        return {
            alwaysFalse: true,
        };
    },
    mounted() {
        // this.addExportOutputCharts();
    },
    methods: {
        copyToClipBoard(index1) {
            // ON CopyTableCountryData.vue WE IMPROVE THE CHART TO HIDE OFF THE SCREEN IF NEED CHANGE THIS LOGIC

            var range = document.createRange();
            let containerNode = document.getElementById(index1 + 'tableSimplified'); //// this part
            // console.log(containerNode);
            containerNode.style.visibility = 'visible';

            range.selectNode(containerNode); //// this part
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
            containerNode.style.visibility = 'hidden';
            alert("data copied");

        },

        formatNumber(num) {
            if(num){
                // split int and decimals of number
                let [int, decimal] = num.toString().split('.');
    
                //format int with , 
                int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
                return decimal ? `${int}.${decimal}` : int
            }
            else{
                return num
            }
        },

        exportToWord(){
            const exportContent = this.$refs.exportContent.innerHTML;
            const preHtml = `
            <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
            <head><meta charset='utf-8'><title>Export HTML to Word Document</title></head><body>`;
            const postHtml = "</body></html>";
            const html = preHtml + exportContent + postHtml;

            const blob = new Blob(['\ufeff', html], {
            type: 'application/msword'
            });

            // Especifica el nombre del archivo
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'exported-document.doc';
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(url);
        },

        addExportOutputCharts(){
            const exportContent =
                {
                    content : this.$refs.exportContent.innerHTML,
                    name : 'SingleSegmentationSimplified',
                }

            this.$store.dispatch('addExportOutputTables', exportContent);
            console.log('1');
        },
    }
};
</script>

<style>
.CopyTable-exportTable th {
    background-color: #3f5f8f;
    border-collapse: collapse;
    border: 1px solid #cad8ef;
    padding: 2px;
    font-family: Arial;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
    vertical-align: top;
}

.CopyTable-exportTable td {
    border-collapse: collapse;
    border: 1px solid #cad8ef;
    padding: 2px;
    font-family: Arial;
    font-size: 11px;
    text-align: right;
}

.CopyTable-exportTable .CopyTable-exportTable-rowDesc {
    text-align: left;
}

.CopyTable-exportTable .CopyTable-exportTable-total {
    font-weight: bold;
}




</style>